<template>
  <div v-if="loadFailed" class="m-2">
    <Card>
      <template #content>
        {{ t("packing-note.load-failed") }}
      </template>
    </Card>
  </div>
  <div v-else class="m-4">
    <div v-if="useTeleport" id="open-picking-navbar-shortcuts">
      <Teleport to="#c-navbar-title">
        <h1>{{ t("packing-note.header") }}</h1>
      </Teleport>
      <Teleport to="#c-navbar-shortcuts">
        <div class="c-header-icon-container"></div>
      </Teleport>
    </div>

    <Card>
      <template #content>
        <PackingNoteList />
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PackingNoteList from "./PackingNoteList.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const loadFailed = ref(false);

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
</script>
