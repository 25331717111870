import { InventoryProductQuantity } from "@/models/inventory/InventoryProductQuantity";
import { InventoryProductCost } from "@/models/inventory/InventoryProductCost";
import { httpClient, AuthHeaders } from "@cumulus/http";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import * as XLSX from "xlsx";
import { WarehouseProduct } from "@/models/inventory/WarehouseProduct";
import { Product } from "@/models/inventory/Product";
import { InventoryChangeLog } from "@/models/inventory/InventoryChangeLog";
import { GetInventoryChangeLogsRequest } from "@/models/inventory/GetInventoryChangeLogsRequest";
import { GetInventoryChangeLogsResponse } from "@/models/inventory/GetInventoryChangeLogsResponse";
import { StockTransferRequest } from "@/models/inventory/InventoryProductTransfer";

class InventoryApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE as string}`
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getWarehouseProduct(
    authHeaders: AuthHeaders,
    warehouseId: string,
    productId: string,
  ): Promise<WarehouseProduct> {
    return await httpClient(this.uri, authHeaders)
      .get(`/warehouses/${warehouseId}/products/${productId}`)
      .then(({ data }) => data);
  }

  public async getProductWithBatches(
    authHeaders: AuthHeaders,
    warehouseId: string,
    productId: string,
  ): Promise<InventoriedProductInfo> {
    return await httpClient(this.uri, authHeaders)
      .get(`/warehouses/${warehouseId}/products/${productId}/with-batches`)
      .then(({ data }) => data);
  }

  public async setNewInventoryLevel(
    authHeaders: AuthHeaders,
    inventoryProductQuantity: InventoryProductQuantity,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(
        `/warehouses/${inventoryProductQuantity.warehouseId}/products/${inventoryProductQuantity.productId}/inventory`,
        inventoryProductQuantity,
      )
      .then(({ data }) => data);
  }

  public async modifyCostPrice(authHeaders: AuthHeaders, inventoryProductCost: InventoryProductCost): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(
        `/warehouses/${inventoryProductCost.warehouseId}/products/${inventoryProductCost.productId}/cost-price`,
        inventoryProductCost,
      )
      .then(({ data }) => data);
  }

  public async exportInventoryProductsToCsv(authHeaders: AuthHeaders, warehouseId: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .get(`/inventory/${warehouseId}/export-csv`, { responseType: "blob" })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory.csv");
        document.body.appendChild(link);
        link.click();
      });
  }

  public async exportInventoryProductsToXlsx(authHeaders: AuthHeaders, warehouseId: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .get(`/inventory/${warehouseId}/export-xlsx`, {
        responseType: "arraybuffer",
      })
      .then(({ data }) => {
        const woorkbook = XLSX.read(data, { type: "array" });
        XLSX.writeFile(woorkbook, "inventory.xlsx");
      });
  }

  // TODO: This is moved to product service
  public async getProductByProductNumber(authHeaders: AuthHeaders, productNumber: string): Promise<Product | null> {
    return await httpClient(this.uri, authHeaders)
      .get(`/products?productNumbers=${productNumber}`)
      .then(({ data }) => {
        if (data.length === 0) {
          return null;
        }
        return data[0];
      });
  }

  public async getInventoryChangeLogs(
    authHeaders: AuthHeaders,
    request: GetInventoryChangeLogsRequest,
  ): Promise<GetInventoryChangeLogsResponse> {
    return await httpClient(this.uri, authHeaders)
      .get("/inventory/inventory-change-logs", {
        params: {
          page: request.page,
          pageSize: request.pageSize,
          reasons: request.filters.reasons,
          fromDate: request.filters.fromDate,
          toDate: request.filters.toDate,
          sortBy: request.sortBy,
          sortOrder: request.sortOrder,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }) => data);
  }

  public async getInventoryChangeLogById(authHeaders: AuthHeaders, id: string): Promise<InventoryChangeLog> {
    return await httpClient(this.uri, authHeaders)
      .get(`/inventory/inventory-change-logs/${id}`)
      .then(({ data }) => data);
  }

  public async transferProductToNewWarehouse(
    authHeaders: AuthHeaders,
    InventoryProductTransfer: StockTransferRequest,
    warehouseId: string,
    productId: string,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(`/warehouses/${warehouseId}/products/${productId}/transfer`, InventoryProductTransfer)
      .then(({ data }) => data);
  }
}

export const inventoryApi = new InventoryApi();
