<template>
  <div class="flex w-full justify-around mt-2">
    <div class="flex flex-col">
      <label for="weight">{{ t("checkout.totalWeight") }}</label>
      <InputNumber id="weight" v-model="pickingFreight.freightWeight" disabled input-class="w-24" />
    </div>
    <div class="flex flex-col">
      <label for="volume">{{ t("checkout.totalVolume") }}</label>
      <InputNumber id="volume" v-model="volumeComputed" disabled input-class="w-24" />
    </div>
    <div class="flex flex-col">
      <label for="Shipping Cost">{{ t("checkout.shippingCost") }}</label>
      <InputNumber
        id="shipping-cost"
        v-model="pickingFreight.shippingCost"
        mode="decimal"
        disabled
        input-class="w-24"
        :min="0"
        suffix=" kr"
        :allow-empty="false"
        :min-fraction-digits="2"
        :max-fraction-digits="2"
        data-testid="checkout-shipping-cost"
      />
    </div>
    <div class="flex flex-col">
      <ShippingPrice
        v-model:price-overriden="freightPriceOverridden"
        v-model:shipping-price="pickingFreight.shippingPrice"
        :agreed-price="agreedPrice"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import ShippingPrice from "./ShippingPrice.vue";
import { Parcel } from "@/goods-out/bring-integration/Parcel";
import { useI18n } from "vue-i18n";
import { PickingFreight } from "@/repositories/picking-list/model/PickingFreight";

const { t } = useI18n();
const volumeComputed = computed(() => props.parcels.map((x) => calculateVolume(x)).reduce((a, b) => a + b));
const calculateVolume = (parcel: Parcel) => parcel.length * parcel.width * parcel.height;

const freightPriceOverridden = defineModel<boolean>("freightPriceOverridden", { default: false, required: true });
const pickingFreight = defineModel("pickingFreight", {
  type: PickingFreight,
  required: true,
});

const props = defineProps<{
  parcels: Parcel[];
  agreedPrice: boolean;
}>();
const agreedPrice = ref(props.agreedPrice);
</script>
