<template>
  <Teleport v-if="isMobile" to="#c-mobile-toolbar">
    <Button class="p-button-text" data-testid="btn-cancel-mobile">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.cancel") }}</span>
    </Button>
    <Button class="p-button-text" data-testid="btn-save-mobile" :disabled="true">
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.start") }}</span>
    </Button>
  </Teleport>

  <div class="flex items-center -mt-2 pb-2 border-b mx-6 py-6">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button class="c-circular-button mr-4" data-testid="btn-cancel" @click="emit('cancelClicked')">
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.cancel") }}</span>
      </Button>
    </div>
    <div>
      <Button class="c-circular-button mr-4" data-testid="btn-save" :disabled="!allowSave" @click="emit('saveClicked')">
        <ProgressSpinner v-if="isStarting" class="w-16 h-16" />
        <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.save") }}</span>
      </Button>
      <Button class="c-circular-button mr-4" data-testid="btn-delete" :disabled="!allowDelete" @click="onConfirmDelete">
        <ProgressSpinner v-if="isDeleting" class="w-16 h-16" />
        <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.delete") }}</span>
      </Button>
      <Button class="c-circular-button" data-testid="btn-start" :disabled="!allowStart" @click="emit('startClicked')">
        <ProgressSpinner v-if="isStarting" class="w-16 h-16" />
        <i v-else class="pi pi-play c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory-count.run-import") }}</span>
      </Button>

      <ConfirmPopup data-testid="inventory-count-job-delete-confirm"></ConfirmPopup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobile } from "@/utils/MobileService";
import { useConfirm } from "primevue/useconfirm";
import { InventoryCountStatus } from "../model/InventoryCountStatus";

const { t } = useI18n();
const { isMobile } = useMobile();
const confirm = useConfirm();

const props = defineProps<{
  inventoryCountJobStatus: InventoryCountStatus;
  isDeleting: boolean;
  isStarting: boolean;
}>();

const emit = defineEmits<{
  (e: "startClicked"): void;
  (e: "saveClicked"): void;
  (e: "deleteClicked"): void;
  (e: "cancelClicked"): void;
  (e: "toggleSidebarClicked"): void;
}>();

const allowSave = computed<boolean>(() => {
  return props.inventoryCountJobStatus === InventoryCountStatus.Ready;
});

const allowStart = computed<boolean>(() => {
  return props.inventoryCountJobStatus === InventoryCountStatus.Ready;
});

const allowDelete = computed<boolean>(() => {
  return (
    props.inventoryCountJobStatus === InventoryCountStatus.Ready ||
    props.inventoryCountJobStatus === InventoryCountStatus.Failed
  );
});

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("inventory-count.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};
</script>
