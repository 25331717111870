import { InventoryCountJob } from "../model/InventoryCountJob";
import isEqual from "lodash.isequal";

export function useRouteFunctions(
  inventoryCountJobSummary: Ref<InventoryCountJob>,
  initialInventoryCountJobSummary: Ref<InventoryCountJob>,
) {
  const router = useRouter();

  const routeToSearchPage = () => {
    router.push({ name: "inventory-count-search" });
  };

  const isInventoryCountJobChanged = computed(() => {
    return !isEqual(inventoryCountJobSummary.value, initialInventoryCountJobSummary.value);
  });

  return {
    routeToSearchPage,
    isInventoryCountJobChanged,
  };
}
