<template>
  <FloatLabel variant="on">
    <InputText
      id="warehouse-short-name"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('warehouse.shortname').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="warehouse-short-name"
      :model-value="warehouseShortName"
      class="inputfield w-full"
      :class="{ 'p-invalid': validate.warehouseShortName.$error }"
      @input="warehouseShortName = ($event.target as HTMLInputElement).value"
    />
    <label for="warehouse-short-name">{{ t("warehouse.shortname") }}</label>
  </FloatLabel>
  <small v-if="validate.warehouseShortName.$error" class="p-error" data-testid="warehouse-short-name-error">
    {{ validate.warehouseShortName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const warehouseShortName = defineModel<string>("warehouseShortName", { required: true, default: "" });

const rules = {
  warehouseShortName: {
    required,
  },
};

const validate = useVuelidate(rules, { warehouseShortName });
</script>
