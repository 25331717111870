<template>
  <Dialog
    :visible="visible"
    :header="isEditing ? t('common.update') : t('common.add')"
    :modal="true"
    class="c-warehouse-dialog"
    @update:visible="cancel"
  >
    <ProgressSpinner v-if="isSaving" class="w-16 h-16" />

    <div class="grid grid-cols-12 gap-4 mb-8 mt-2">
      <div class="col-span-8">
        <WarehouseName v-model:warehouse-name="warehouse.name" v-model:existing-warehouse-names="warehouseNames" />
      </div>
      <div class="col-span-4">
        <WarehouseShortName v-model:warehouse-short-name="warehouse.shortName" />
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-8">
      <div class="col-span-4">
        <WarehouseClient v-model:client-id="warehouse.clientId" />
      </div>
      <div class="col-span-4">
        <WarehouseCurrency v-model:currency-iso="warehouse.currencyIso" />
      </div>
      <div class="col-span-4">
        <WarehouseState v-model:warehouse-state="warehouse.state" />
        <small v-if="showIsActiveError" data-testid="active-delete-error" class="p-error">{{
          t("delete.active-error")
        }}</small>
      </div>
    </div>

    <div class="mb-8">
      <Panel :header="t('warehouse.warehouse-address')" class="c-toggle-panel">
        <div class="grid grid-cols-12 gap-4">
          <div class="field col-span-12">
            <WarehouseCompanyName v-model:warehouse-company-name="warehouse.address.companyName" />
          </div>
          <div class="field col-span-12">
            <WarehouseAddressLines v-model:warehouse-address="warehouse.address.addressLines" />
          </div>
          <div class="field col-span-12">
            <WarehouseCountry v-model:country-iso="warehouse.address.countryIso" />
          </div>
          <div class="field col-span-12">
            <WarehousePostalCode
              v-model:warehouse-postal-code="warehouse.address.postalCode"
              v-model:warehouse-city="warehouse.address.city"
            />
          </div>
          <div class="field col-span-12">
            <WarehouseCity v-model:warehouse-city="warehouse.address.city" />
          </div>
        </div>
      </Panel>
    </div>
    <div v-if="errorReason !== ''" class="p-error flex justify-center mt-2">{{ errorReason }}</div>
    <template #footer>
      <div class="w-full">
        <div class="flex justify-between items-center">
          <div class="">
            <div v-if="isEditing" class="">
              <Button
                :label="t('common.delete')"
                data-testid="delete-btn"
                :disabled="isSaving"
                severity="danger"
                @click="confirmDelete"
              />
              <ConfirmPopup data-testid="delete-confirm"></ConfirmPopup>
            </div>
          </div>
          <div class="">
            <Button :label="t(`common.cancel`)" data-testid="cancel-btn" @click="cancel" />

            <Button
              :label="isEditing ? t('common.update') : t('common.add')"
              data-testid="btn-commit"
              severity="success"
              class="ml-2"
              @click="onCommit"
            />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { useConfirm } from "primevue/useconfirm";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { WarehouseStates } from "@/repositories/warehouse/model/WarehouseStates";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const props = defineProps<{
  showDialog: boolean;
  warehouse: Warehouse;
  warehouses: Array<Warehouse>;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "onCreateWarehouse"): void;
  (e: "onUpdateWarehouse", value: Warehouse): void;
  (e: "onDeleteWarehouse"): void;
}>();

const { t } = useI18n();
const val = useVuelidate();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const { createWarehouse, updateWarehouse, deleteWarehouse } = useWarehouse();
const warehouse = ref<Warehouse>(cloneDeep(props.warehouse));
const warehouseNames = ref<Array<string>>(props.warehouses.map(({ name }) => name));
const initialWarehouse = ref<Warehouse>(cloneDeep(warehouse.value));
const isEditing = ref(false);
const isSaving = ref(false);
const showIsActiveError = ref(false);
const errorReason = ref("");

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const cancel = () => {
  if (confirmClose()) {
    visible.value = false;
  }
};

const confirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      onDeleteWarehouse();
    },
  });
};

const onDeleteWarehouse = () => {
  if (warehouse.value.state === WarehouseStates.Active) {
    toast.add({
      severity: "warn",
      summary: t("toast.error.summary"),
      detail: t("toast.error.active-error"),
      closable: true,
    });
    showIsActiveError.value = true;
    return;
  }
  repositoryHandler("delete");
};

const onCommit = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
      closable: true,
    });
    return;
  }
  if (!isEditing.value) {
    repositoryHandler("create");
  } else if (isEditing.value) {
    repositoryHandler("update");
  }
};

const repositoryHandler = async (repo: string) => {
  try {
    isSaving.value = true;

    switch (repo) {
      case "create":
        await createWarehouse(warehouse.value);
        emit("onCreateWarehouse");
        visible.value = false;
        break;
      case "update":
        await updateWarehouse(warehouse.value);
        emit("onUpdateWarehouse", warehouse.value);
        visible.value = false;
        break;
      case "delete":
        await deleteWarehouse(warehouse.value.id);
        emit("onDeleteWarehouse");
        visible.value = false;
        break;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isSaving.value = false;
  }
};

const onInit = () => {
  if (props.warehouse.id) {
    isEditing.value = true;
  }
};

onMounted(onInit);

const hasUpdates = computed(() => {
  return !isEqual(warehouse.value, initialWarehouse.value);
});

const { confirmClose } = useUnsavedChanges(hasUpdates);
</script>

<style lang="scss">
.c-warehouse-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
