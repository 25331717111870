<template>
  <h4 class="text-center my-3">
    {{ t("order-ready-for-picking.shipping-date") }}
  </h4>
  <div class="mb-5">
    <div class="col-span-12 mb-3">
      <CumulusDatePicker
        v-model:date="fromDateComputed"
        :label="t('common.filters.filter-date-from', { symbol: ':' })"
        :data-test-id="'from-date-filter'"
        :debounce-time="800"
      />
    </div>
    <div class="col-span-12">
      <CumulusDatePicker
        v-model:date="toDateComputed"
        :label="t('common.filters.filter-date-to', { symbol: ':' })"
        :data-test-id="'to-date-filter'"
        :debounce-time="800"
      />
    </div>
  </div>

  <Button
    :label="t('common.filters.filter-last-ten-days')"
    severity="secondary"
    variant="outlined"
    class="w-full dark:border-surface-600 border"
    data-testid="last-10-days-filter-btn"
    @click="setDateFilterLastDays(10)"
  />

  <hr class="my-6" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-order-status") }}
  </h4>
  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md"
    :class="{
      'bg-sky-100 dark:bg-slate-800': index % 2 === 0,
      'bg-slate-50 dark:bg-slate-600': index % 2 === 1,
    }"
  >
    <label :for="'order-status-' + value.status.toLowerCase()">{{
      t(`common.status.${value.status.toLowerCase()}`)
    }}</label>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :input-id="'order-status-' + value.status.toLowerCase()"
        :data-testid="'order-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>

  <hr class="my-6" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-customer") }}
  </h4>
  <AutoComplete
    v-model="customerNumbersComputed"
    class="w-full"
    data-testid="customer-number-filter"
    :placeholder="t('common.filters.filter-customer-number')"
    multiple
    fluid
    :typeahead="false"
  ></AutoComplete>
  <MultiSelect
    id="customer-groups"
    v-model="selectedCustomerGroupsIdComputed"
    :placeholder="
      t('placeholder.select', {
        property: t('common.filters.filter-customer-group').toLowerCase(),
      })
    "
    option-label="name"
    option-value="id"
    :options="customerGroups"
    display="chip"
    class="w-full field mt-4"
    :filter="true"
    :loading="loadingCustomerGroups"
    pt:list:data-testid="customer-groups-list"
  />

  <hr class="my-6" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-product") }}
  </h4>
  <AutoComplete
    v-model="productNumbersComputed"
    class="w-full"
    data-testid="product-number-filter"
    :placeholder="t('common.filters.filter-product-number')"
    multiple
    fluid
    :typeahead="false"
  ></AutoComplete>

  <hr class="my-6" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-freight") }}
  </h4>
  <MultiSelect
    id="freight-methods"
    v-model="selectedFreightMethodComputed"
    :options="freightMethods"
    option-label="name"
    option-value="id"
    class="w-full field mt-4"
    :placeholder="
      t('placeholder.select', {
        property: t('common.filters.filter-freight').toLowerCase(),
      })
    "
    display="chip"
    :filter="true"
    :loading="loadingFreightMethods"
    pt:list:data-testid="freight-methods-list"
  />
</template>

<script setup lang="ts">
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDatePicker } from "@cumulus/components";
import { OrderStatus } from "@/repositories/search/model/order/OrderStatus";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import type { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";

const { t } = useI18n();

const props = defineProps<{
  filters: SearchFilters;
  loading: boolean;
  loadingFreightMethods: boolean;
  loadingCustomerGroups: boolean;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    toDate = toDate.replace("T00:00:00.000Z", "T23:59:59.999Z");
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const setDateFilterLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);

  const filters = {
    ...props.filters,
    toDate: toDate.toJSON(),
    fromDate: fromDate.toJSON(),
  };
  emit("update:filters", filters);
};

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(OrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<OrderStatus[]>({
  get: () => props.filters.orderStatuses,
  set: (value) => {
    const filters = { ...props.filters, orderStatuses: value };
    emit("update:filters", filters);
  },
});

const customerNumbersComputed = computed<string[]>({
  get: () => props.filters.customerNumbers,
  set: (value) => {
    const filters = { ...props.filters, customerNumbers: value };
    emit("update:filters", filters);
  },
});

const selectedCustomerGroupsIdComputed = computed<string[]>({
  get: () => props.filters.customerGroups,
  set: (value) => {
    const filters = { ...props.filters, customerGroups: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const selectedFreightMethodComputed = computed<string[]>({
  get: () => props.filters.freightMethods,
  set: (value) => {
    const filters = { ...props.filters, freightMethods: value };
    emit("update:filters", filters);
  },
});
</script>
