<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6"></div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('packing-note.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="c-refresh-button"
        severity="secondary"
        text
        @click="emit('packingNoteRefresh')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('packing-note.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-column-chooser"
        severity="secondary"
        text
        :class="{ 'c-toolbar-button-active': props.isChooserVisible }"
        @click="(event: Event) => emit('openColumnChooser', event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  isChooserVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "openColumnChooser", event: Event): void;
  (e: "packingNoteRefresh"): void;
}>();
</script>
