<template>
  <div class="c-chips">
    <ul v-if="hasActiveFilters" class="c-chips-multiple-container" data-testid="c-active-filters-container">
      <li>
        <Button
          type="button"
          :label="t('common.search-filters.clear')"
          icon="pi pi-times-circle"
          @click="clearFilters"
          class="text-sm mr-4 p-1.5"
          data-testid="clear-filter-btn"
          rounded
        />
      </li>
      <FilterItem
        v-if="filters.productNumbers.length !== null"
        :values="filters.productNumbers"
        filterType="productNumbers"
        @removeFilter="removeFilter"
        :label="t('common.filters.filter-product-number')"
      />
      <FilterItem
        v-if="filters.customerNumbers.length !== null"
        :values="filters.customerNumbers"
        filterType="customerNumbers"
        @removeFilter="removeFilter"
        :label="t('common.filters.filter-customer-number')"
      />
      <FilterItem
        v-if="filters.fromDate !== null"
        :values="d(filters.fromDate)"
        filterType="fromDate"
        @removeFilter="removeFilter"
        :label="t('common.filters.filter-date-from')"
      />
      <FilterItem
        v-if="filters.toDate !== null"
        :values="d(filters.toDate)"
        filterType="toDate"
        @removeFilter="removeFilter"
        :label="t('common.filters.filter-date-to')"
      />
      <FilterItem
        v-if="filters.orderStatuses.length !== null"
        :values="filters.orderStatuses.map((status: string) => t(`common.status.${status.toLowerCase()}`))"
        filterType="orderStatuses"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.freightMethods.length !== null"
        :values="freightMethodsSelectedComputed"
        filterType="freightMethods"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerGroups.length !== null"
        :values="customerGroupsSelectedComputed"
        filterType="customerGroups"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import FilterItem from "./FilterItem.vue";

const { t, d } = useI18n();

const props = defineProps<{
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const clearFilters = () => {
  const filters = new SearchFilters();
  emit("update:filters", filters);
};

const removeFilter = (filterType: keyof SearchFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof SearchFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType] = null;
  }

  emit("update:filters", filters);
};

const freightMethodsSelectedComputed = computed<string[]>(() => {
  return props.filters.freightMethods.map((freightMethodsId: string) => {
    const freightMethod = props.freightMethods.find((m) => m.id === freightMethodsId);
    return freightMethod?.name ?? "";
  });
});

const customerGroupsSelectedComputed = computed<string[]>(() => {
  return props.filters.customerGroups.flatMap((groupLevelId: string) => {
    const groupLevel = props.customerGroups.find((m) => m.id === groupLevelId);
    return groupLevel?.name ?? "";
  });
});

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.productNumbers.length > 0 ||
    props.filters.orderStatuses.length > 0 ||
    props.filters.customerGroups.length > 0 ||
    props.filters.freightMethods.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null ||
    props.filters.customerNumbers.length > 0
  );
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
