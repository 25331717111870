<template>
  <div class="c-page-content">
    <div v-if="loadFailed" class="c-picking-list">
      <Card>
        <template #content>
          {{ t("picking-list.load-failed") }}
        </template>
      </Card>
    </div>
    <div v-else class="ml-9 mr-7 mt-5">
      <div>
        <Card>
          <template #content>
            <OpenPickingLists
              v-model:selected-picking-list="selectedPickingList"
              @show-picking-list="showPickingList"
              @on-start-picking="onStartPicking"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import OpenPickingLists from "./OpenPickingLists.vue";
import { useI18n } from "vue-i18n";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import router from "@/router/router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { NIL as emptyUuid } from "uuid";

const pickingListStore = usePickingListStore();

const { startPicking } = pickingListStore;
const { getUser } = useAuth();
const loadFailed = ref(false);
const { t } = useI18n();
const toast = useCumulusToast(useToast());
const selectedPickingList = ref<PickingList>();

const onStartPicking = async () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  const user = await getUser();

  if (
    selectedPickingList.value.state === PickingListState.InPicking &&
    selectedPickingList.value.assignedEmployeeId == user.getEmployee().id
  ) {
    router.push({
      name: "picking-list",
      params: { pickingListId: selectedPickingList.value.id },
      query: { editMode: "true" },
    });
    return;
  }

  try {
    await startPicking(selectedPickingList.value.id);
  } catch {
    return;
  }

  router.push({
    name: "picking-list",
    params: { pickingListId: selectedPickingList.value.id },
    query: { editMode: "true" },
  });
};

const showPickingList = () => {
  selectedPickingList.value != null && selectedPickingList.value.id !== emptyUuid
    ? router.push({ name: "picking-list", params: { pickingListId: selectedPickingList.value.id } })
    : toast.add({
        severity: "warn",
        summary: "summary",
        detail: "detail",
      });
};
</script>
