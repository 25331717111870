<template>
  <InventoryProductsHeader
    :selected-icon="selectedIcon"
    :warehouses="warehouses"
    :is-filter-visible="showSidebar"
    :is-chooser-visible="isChooserVisible"
    :is-sorting-visible="isSortingVisible"
    @open-detailed-overview="emit('openInventoryProductOverview')"
    @edit-inventory-product-quantity="emit('openEditInventoryQuantity')"
    @edit-inventory-product-cost="emit('openEditInventoryCost')"
    @inventory-changes-log-btn-clicked="emit('inventoryChangesLogBtnClicked')"
    @refresh-list="emit('inventoryRefresh')"
    @on-toggle-filter-sidebar="emit('onToggleFilterSidebar')"
    @open-column-chooser="openColumnChooser"
    @open-sort-list="openSortList"
  />
  <FilterOverview v-model:filters="filtersComputed" :manufacturers="manufacturers" />
  <DataTable
    ref="inventoryProductTable"
    :key="renderKey as unknown as number"
    v-model:context-menu-selection="selectedProductComputed"
    v-model:selection="selectedProductComputed"
    :value="products"
    data-key="id"
    :auto-layout="true"
    responsive-layout="scroll"
    selection-mode="single"
    class="c-datatable"
    :loading="loading"
    context-menu
    paginator
    :rows="pageSize"
    :rows-per-page-options="[50, 100]"
    striped-rows
    data-testid="inventory-product-list-table"
    :lazy="true"
    :total-records="totalHits"
    :current-page-report-template="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :sort-order="sortOrder"
    :sort-field="sortField"
    scrollable
    scroll-height="75vh"
    :resizable-columns="true"
    column-resize-mode="fit"
    removable-sort
    :reorderable-columns="true"
    @row-contextmenu="onRowContextMenu"
    @row-select="onRowSelected"
    @row-dblclick="onRowDoubleClicked"
    @sort="onSort"
    @page="onPage"
    @column-resize-end="onColumnResizeEnd"
    @column-reorder="onColumnReorder"
  >
    <Column
      v-for="(col, subIndex) of selectedColumnsComputed as unknown as DataTableColumn[]"
      :key="col.field + '_' + subIndex"
      :field="col.field"
      :header="t(col.header)"
      :class="col.class"
      :sortable="col.sortable"
      :pt="{
        headerCell: {
          id: col.field,
        },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template #body="{ data, field, index: rowIndex }">
        <template v-if="col.field === InventoryProductListColumn.ProductNumber">
          {{ data[field as keyof typeof data] }}
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.Name">
          <div class="flex justify-between items-center">
            <span class="c-product-name-text" @click="onProductNameClicked(data, rowIndex)">{{ data.name }}</span>
            <i
              role="button"
              class="pi pi-ellipsis-v c-context-menu-button"
              data-testid="context-menu-button"
              @click="(e) => openEllipsisContextMenu(e, data, rowIndex)"
            >
            </i>
          </div>
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.Gtin">
          <span>{{ data.gtin }}</span>
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.Inventory">
          {{ getProductStock(data) }}
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.InPickList">
          {{ getProductAllocatedForPick(data) }}
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.Available">
          {{ getProductAvailable(data) }}
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.CostPrice">
          {{ getProductCostPrice(data) }}
        </template>
        <template v-else-if="col.field === InventoryProductListColumn.TotalInventoryCost">
          {{ getInventoryValue(data) }}
        </template>
        <template v-else>
          {{ data[field as keyof typeof data] }}
        </template>
      </template>
    </Column>

    <template #loading>
      <span v-if="loading">{{ t("common.loading") }}</span>
    </template>
    <template #empty>
      <span>{{ t("inventory.no-products-found") }}</span>
    </template>
  </DataTable>
  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="resetColumns"
  />
  <Popover ref="op" data-testid="sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in inventoryProductSearchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="onSelectedRow(column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>
  <ContextMenu ref="contextMenu" :model="menuModel" pt:root:data-testid="inventory-context-row-menu" />
  <ContextMenu ref="ellipsisContextMenu" :model="ellipsisMenuModel" />
</template>

<script setup lang="ts">
import { SearchProduct } from "@/repositories/search/model/product/SearchProduct";
import {
  type DataTablePageEvent,
  type DataTableRowContextMenuEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableRowSelectEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { computed, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { InventoryProductListColumn } from "@/models/inventory/search/InventoryProductListColumns";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import PopOverColumnChooser from "@/warehouse/PopOverColumnChooser.vue";

import FilterOverview from "../search/FilterOverview.vue";
import InventoryProductsHeader from "../search/InventoryProductsHeader.vue";
import type { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import type { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import type { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";

const contextMenu = ref();
const ellipsisContextMenu = ref();
const inventoryProductTable = ref();
const previouslyFocusedRow = ref();
const isTransferContextVisible = ref(false);
const chooserRef = ref();
const { t, n } = useI18n();
const warehouses = defineModel<Warehouse[]>("warehouses", { default: () => [] });
const manufacturers = defineModel<Manufacturer[]>("manufacturers", { default: () => [] });
const op = ref();
const currentIconIndex = ref(0);

const props = defineProps<{
  products: SearchProduct[];
  selectedInventoryProduct: SearchProduct | null;
  selectedWarehouseId: string;
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  showSidebar: boolean;
  filters: ProductSearchFilters;
}>();

const emit = defineEmits<{
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "rowSelected", payload: DataTableRowSelectEvent): void;
  (e: "rowDoubleClicked", payload: DataTableRowDoubleClickEvent): void;
  (e: "openInventoryProductOverview"): void;
  (e: "openEditInventoryQuantity"): void;
  (e: "openEditInventoryCost"): void;
  (e: "inventoryChangesLogBtnClicked"): void;
  (e: "update:selectedInventoryProduct", payload: SearchProduct | null): void;
  (e: "inventoryRefresh"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "openTransferInventory"): void;
  (e: "update:filters", value: ProductSearchFilters): void;
}>();

const inventoryProductSearchColumns = [
  { field: "productNumber", header: "inventory.productnumber", sortable: true },
  {
    field: "name",
    header: "inventory.productname",
    sortable: true,
  },
  {
    field: "gtin",
    header: "inventory.ean",
    sortable: true,
  },
  {
    field: "availability.quantityInStock",
    header: "inventory.quantity-in-inventory",
    class: "text-center",
    sortable: true,
  },
  {
    field: "availability.quantityAllocatedForPicking",
    header: "inventory.quantity-in-picking-list",
    sortable: true,
  },

  {
    field: "availability.quantityAvailableFromStock",
    header: "inventory.quantity-available",
    sortable: true,
  },
  {
    field: "availability.costPrice",
    header: "inventory.cost-price",
    sortable: true,
  },
  {
    field: "availability.totalInventoryCost",
    header: "inventory.total-inventory-cost",
    sortable: true,
    class: "text-center",
  },
];

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: ProductSearchFilters) => {
    emit("update:filters", value);
  },
});

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const defaultSortField = ref(
  inventoryProductSearchColumns.find((column) => column.field === InventoryProductListColumn.Name),
);
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const clearSelectedRow = (newValue: boolean) => {
  if (newValue) {
    return;
  }
  clearSelectedInventoryProduct();

  if (previouslyFocusedRow.value) {
    previouslyFocusedRow.value.focus();
  }
};

defineExpose({
  clearSelectedRow,
});

const { selectedColumnsComputed, renderKey, onColumnReorder, onColumnResizeEnd, resetColumns, orderedColumns } =
  useTablePreferences("inventoryProductSearch", inventoryProductSearchColumns, null);

const selectedProductComputed = computed<SearchProduct>({
  get: () => props.selectedInventoryProduct ?? new SearchProduct(),
  set: (product) => emit("update:selectedInventoryProduct", product),
});

const getProductStock = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityInStock : 0;
};

const getProductAllocatedForPick = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityAllocatedForPicking : 0;
};

const getProductAvailable = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityAvailableFromStock : 0;
};

const getProductCostPrice = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);

  return availability ? n(availability.costPrice, "decimal") : 0;
};

const getInventoryValue = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability?.totalInventoryCost ?? 0;
};

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const onProductNameClicked = (product: SearchProduct, index: number) => {
  setFocusedRow(index);
  selectedProductComputed.value = product;
  emit("openInventoryProductOverview");
};

const openEllipsisContextMenu = (event: Event, data: SearchProduct, index: number) => {
  setFocusedRow(index);
  selectedProductComputed.value = data;
  ellipsisContextMenu.value.show(event);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const menuModel = ref([
  {
    label: t("inventory.context-menu.detailed-overview"),
    icon: "pi pi-fw pi-search",
    command: () => emit("openInventoryProductOverview"),
  },
  {
    label: t("inventory.edit-transfer.btn"),
    visible: isTransferContextVisible,
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openTransferInventory"),
  },
  {
    label: t("inventory.context-menu.change-inventory"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryQuantity"),
  },
  {
    label: t("inventory.context-menu.change-cost"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryCost"),
  },
]);

const ellipsisMenuModel = ref([
  {
    label: t("inventory.context-menu.detailed-overview"),
    icon: "pi pi-fw pi-search",
    command: () => emit("openInventoryProductOverview"),
  },
  {
    label: t("inventory.context-menu.change-inventory"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryQuantity"),
  },
  {
    label: t("inventory.context-menu.change-cost"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryCost"),
  },
  {
    label: t("inventory.context-menu.copy"),
    icon: "pi pi-copy",
    command: () => copyToClipboard(),
  },
]);

const onRowSelected = (event: DataTableRowSelectEvent) => {
  setFocusedRow(event.index);
  emit("rowSelected", event);
};

const onRowDoubleClicked = (event: DataTableRowDoubleClickEvent) => {
  setFocusedRow(event.index);
  emit("rowDoubleClicked", event);
};

const clearSelectedInventoryProduct = () => {
  selectedProductComputed.value = new SearchProduct();
};

const copyToClipboard = () => {
  navigator.clipboard.writeText(selectedProductComputed.value.name);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  event.data.warehouseIds.length > 1
    ? (isTransferContextVisible.value = true)
    : (isTransferContextVisible.value = false);

  setFocusedRow(event.index);
  selectedProductComputed.value = event.data as SearchProduct;
  contextMenu.value.show(event.originalEvent);
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = inventoryProductTable.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};
</script>

<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

.c-context-menu-button {
  visibility: hidden;
  font-size: 16px;
}

.c-context-menu-button:hover {
  outline: 4px solid;
  outline-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
}
.c-context-menu-button:active {
  outline: 4px solid;
  outline-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
}
.c-product-name-text:hover {
  text-decoration: underline;
}
</style>
