export const InventoryProductListCountColumn = {
  ProductNumber: "productNumber",
  Name: "productName",
  QuantityInStock: "quantityInStock",
  CostPrice: "costPrice",
  NewQuantity: "newQuantity",
  NewCostPrice: "newCostPrice",
  DiffInventory: "diffInventory",
  DiffCostPrice: "diffCostPrice",
} as const;
