<template>
  <OrderReadyForPickingHeader
    :selected-icon="selectedIcon"
    :is-filter-visible="showSidebar"
    :is-chooser-visible="isChooserVisible"
    :is-sorting-visible="isSortingVisible"
    :selected-orders-ready-for-picking="selectedOrdersReadyForPicking"
    @create-picking-lists="emit('createPickingLists')"
    @on-toggle-filter-sidebar="emit('onToggleFilterSidebar')"
    @open-column-chooser="openColumnChooser"
    @open-sort-list="openSortList"
    @refresh-list="emit('orderReadyForPickingRefresh')"
    @select-all="selectAll"
  />
  <div class="flex items-left gap-2 mt-3">
    <FilterOverview
      v-model:filters="filtersComputed"
      :customer-groups="customerGroups"
      :freight-methods="freightMethods"
    />
  </div>
  <DataTable
    ref="ordersReadyForPickingRef"
    :key="renderKey as unknown as number"
    v-model:selection="selectedOrdersReadyForPickingComputed"
    :value="ordersReadyForPicking"
    data-key="orderId"
    :auto-layout="true"
    responsive-layout="scroll"
    breakpoint="999px"
    selection-mode="multiple"
    class="c-datatable"
    :loading="loading"
    context-menu
    :context-menu-selection="selectedOrdersReadyForPickingComputed"
    :paginator="true"
    :current-page-report-template="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows="pageSize"
    :rows-per-page-options="[50, 100]"
    data-testid="order-ready-for-picking-list-table"
    :lazy="true"
    striped-rows
    :total-records="totalHits"
    :sort-order="sortOrder"
    :sort-field="sortField"
    scrollable
    scroll-height="75vh"
    :resizable-columns="true"
    column-resize-mode="fit"
    removable-sort
    :reorderable-columns="true"
    @row-contextmenu="onRowContextMenu"
    @sort="onSort"
    @page="onPage"
    @column-resize-end="onColumnResizeEnd"
    @column-reorder="onColumnReorder"
  >
    <Column
      v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumnExt[]"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="t(col.header)"
      :class="col.class"
      :sortable="col.sortable"
      :selection-mode="col.selectionMode"
      :pt="{
        headerCell: {
          id: col.field,
        },
        columntitle: {
          class: {
            hidden: col.field === OrderReadyForPickingListColumn.SelectPickingList,
          },
        },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template v-if="col.field != OrderReadyForPickingListColumn.SelectPickingList" #body="{ data, field }">
        <template v-if="col.field === OrderReadyForPickingListColumn.ShippingDate">
          {{ d(data.shippingDate, "short") }}
        </template>

        <template v-else-if="col.field === OrderReadyForPickingListColumn.OrderNumber">
          {{ data.orderNumber }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.CustomerNumber">
          {{ data.customerNumber }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.CustomerName">
          {{ data.customerName }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.OrderReference">
          {{ data.orderReference }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.FreightMethodName">
          {{ data.freightMethodName }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.QuantityOfOrderLines">
          {{ data.quantityOfOrderLines }}
        </template>
        <template v-else-if="col.field === OrderReadyForPickingListColumn.SumTotalLines">
          {{ data.sumTotalLines }}
        </template>
        <template v-else>
          {{ data[field as keyof typeof data] }}
        </template>
      </template>
    </Column>
    <template #loading>
      <span v-if="loading">{{ t("common.loading") }}</span>
    </template>
    <template #empty>
      <span>{{ t("order-ready-for-picking.no-orders-ready-for-picking-found") }}</span>
    </template>
  </DataTable>
  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="resetColumns"
  />
  <PopOverSorting
    ref="op"
    v-model:selected-icon="selectedIcon"
    :search-columns="filteredSortedColumns"
    :default-sort-field="defaultSortField"
    @on-selected-row="onSelectedRow"
  />
  <ContextMenu ref="contextMenu" :model="menuModel" />
</template>
<script setup lang="ts">
import { OrderReadyForPicking } from "@/repositories/search/model/order/OrderReadyForPicking";
import {
  type DataTablePageEvent,
  type DataTableRowContextMenuEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { OrderReadyForPickingListColumn } from "./model/OrderReadyForPickingListColumn";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumnExt } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import OrderReadyForPickingHeader from "./OrderReadyForPickingHeader.vue";
import FilterOverview from "./FilterOverview.vue";
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import type { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { PopOverSorting, PopOverColumnChooser } from "@cumulus/components";

const { t, d } = useI18n();
const currentIconIndex = ref(0);
const contextMenu = ref();
const chooserRef = ref();
const op = ref();
const selectAll = ref(false);

const props = defineProps<{
  ordersReadyForPicking: OrderReadyForPicking[];
  selectedOrdersReadyForPicking: OrderReadyForPicking[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  showSidebar: boolean;
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "createPickingLists"): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:page", value: number): void;
  (e: "update:selectedOrdersReadyForPicking", value: OrderReadyForPicking[]): void;
  (e: "update:pageSize", value: number): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
  (e: "orderReadyForPickingRefresh"): void;
  (e: "selectAll"): void;
}>();

const ordersReadyForPickingSearchColumns: DataTableColumnExt[] = [
  {
    field: "selectPickingList",
    header: "order-ready-for-picking.checkbox",
    class: "c-col-selectPickingList w-12",
    sortable: false,
    selectionMode: "multiple",
  },
  {
    field: "shippingDate",
    header: "order-ready-for-picking.shipping-date",
    sortable: false,
  },
  {
    field: "orderNumber",
    header: "order-ready-for-picking.orderNumber",
    class: "text-right",
    sortable: true,
  },
  {
    field: "customer.customerNumber",
    header: "order-ready-for-picking.customerNumber",
    class: "text-right",
    sortable: true,
  },
  {
    field: "customer.customerName",
    header: "order-ready-for-picking.name",
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderReference",
    header: "order-ready-for-picking.orderReference",
    sortable: true,
  },
  {
    field: "freightMethodName",
    header: "order-ready-for-picking.freightMethod",
    sortable: true,
  },
  {
    field: "orderlines",
    header: "order-ready-for-picking.quantity-of-order-items",
    class: "text-right",
    sortable: false,
  },
  {
    field: "sumTotalLines",
    header: "order-ready-for-picking.sumTotalLines",
    class: "text-right",
    sortable: false,
  },
];

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("ordersReadyForPickingSearch", ordersReadyForPickingSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const filteredSortedColumns = computed(() => {
  return orderedColumns.value.filter((col) => col.field !== OrderReadyForPickingListColumn.SelectPickingList);
});

const defaultSortField = ref(
  ordersReadyForPickingSearchColumns.find((column) => column.field === OrderReadyForPickingListColumn.CustomerName),
);

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: SearchFilters) => {
    emit("update:filters", value);
  },
});

const selectedOrdersReadyForPickingComputed = computed({
  get: () => props.selectedOrdersReadyForPicking,
  set: (value) => emit("update:selectedOrdersReadyForPicking", value),
});

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  const ordersReadyForPicking = [event.data] as OrderReadyForPicking[];

  emit("update:selectedOrdersReadyForPicking", ordersReadyForPicking);
  contextMenu.value.show(event.originalEvent);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const menuModel = ref([
  {
    label: t("order-ready-for-picking.context-menu.create-picking-lists"),
    icon: "pi pi-fw pi-search",
    command: () => emit("createPickingLists"),
  },
]);
</script>
<style scoped lang="scss">
::v-deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
