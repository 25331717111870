<template>
  <Dialog
    id="inventory-quantity-edit"
    v-model:visible="visible"
    :header="t('inventory.edit-inventory.header', { inventoryProduct: inventoriedProductInfo.productName })"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    :close-on-escape="false"
    :closable="false"
  >
    <div v-if="loading" class="c-loading-overlay">
      <ProgressSpinner class="w-16 h-16" />
    </div>
    <div v-if="loadFailed">
      {{ t("inventory.load-failed-inventoried-product") }}
    </div>
    <div v-else class="c-change-inventory">
      <div class="grid grid-cols-12 gap-4 mb-4">
        <div class="col-span-12 md:col-span-8">
          <ValueWithHeader
            id="inventory-product-number"
            align-text="left"
            :label="t('inventory.productnumber')"
            :value="inventoriedProductInfo.productNumber"
          />
        </div>
        <div class="col-span-12 md:col-span-4">
          <ValueWithHeader
            id="inventory-gti-number"
            align-text="left"
            :label="t('inventory.ean')"
            :value="inventoriedProductInfo.gtin"
          />
        </div>
        <div class="col-span-12">
          <ValueWithHeader
            id="inventory-product-name"
            align-text="left"
            :label="t('inventory.productname')"
            :value="inventoriedProductInfo.productName"
          />
        </div>
      </div>

      <div class="grid grid-cols-12 gap-4 mb-4">
        <div class="col-span-12 md:col-span-3 md:text-center">
          <ValueWithHeader
            id="inventory-quantity-in-picking-list"
            align-text="right"
            :label="t(`inventory.quantity-in-picking-list`)"
            :value="inventoriedProductInfo.quantityAllocatedForPicking"
          />
        </div>
        <div class="col-span-12 md:col-span-3 md:text-center">
          <ValueWithHeader
            id="inventory-total-cost-price"
            align-text="right"
            :label="t(`inventory.edit-inventory.existing-inventory`)"
            :value="inventoriedProductInfo.quantityInStock"
          />
        </div>
        <div class="col-span-12 md:col-span-3 md:text-center">
          <NewInventoryValue
            v-model:new-quantity="inventoryProductQuantity.newQuantity"
            :current-quantity="inventoriedProductInfo.quantityInStock"
          />
        </div>
        <div class="col-span-12 md:col-span-3 md:text-center">
          <ChangedInventoryValue
            :changed-inventory-value="inventoryProductQuantity.newQuantity - inventoryProductQuantity.oldQuantity"
          />
        </div>
      </div>

      <div class="flex flex-wrap justify-between items-end mb-4">
        <div class="col-span-12 md:col-span-8 lg:col-span-6 px-0">
          <QuantityChangeReason v-model:reason="inventoryProductQuantity.reason" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <div class="formgroup-inline mt-4">
            <div class="field-checkbox">
              <label for="inventory-print" class="">{{ t("inventory.edit-inventory.print") }}</label>
              <Checkbox id="inventory-print" v-model="printChangedInventory" :binary="true" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 mb-8">
        <Comment v-model:comment="inventoryProductQuantity.comment" />
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            class="c-dialog-default-button mr-6"
            data-testid="cancel-btn"
            :disabled="saving"
            text
            @click="onCancel"
          />
          <div v-tooltip.bottom="saveButtonTooltip">
            <Button
              :label="t(`common.save`)"
              class="c-dialog-default-button c-dialog-success-button"
              data-testid="btn-save-inventory"
              :disabled="saving || !hasUpdates"
              @click="onSave"
            />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useInventory } from "@/api/inventory/InventoryService";
import { InventoryProductQuantity } from "@/models/inventory/InventoryProductQuantity";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";

import NewInventoryValue from "../components/quantity-edit/NewInventoryValue.vue";
import ChangedInventoryValue from "../components/quantity-edit/ChangedInventoryValue.vue";
import QuantityChangeReason from "../components/quantity-edit/QuantityChangeReason.vue";
import ValueWithHeader from "../components/overview/ValueWithHeader.vue";
import Comment from "../components/Comment.vue";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { BadRequestError } from "@cumulus/http";

const props = defineProps<{
  showEditQuantityDialog: boolean;
  productId: string;
  warehouseId: string;
}>();

const emit = defineEmits<{
  (e: "update:showEditQuantityDialog", value: boolean): void;
}>();

const { t } = useI18n();
const { setNewInventoryLevel, getProductWithBatches } = useInventory();
const printChangedInventory = ref(true);
const val = useValidate();
const toast = useCumulusToast(useToast());
const saving = ref(false);
const loading = ref(false);
const loadFailed = ref(false);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const inventoryProductQuantity = ref<InventoryProductQuantity>(new InventoryProductQuantity());
const inventoriedProductInfo = ref<InventoriedProductInfo>(new InventoriedProductInfo());

const initialInventoryProductQuantity = ref<InventoryProductQuantity>(new InventoryProductQuantity());

const visible = computed<boolean>({
  get: () => {
    return props.showEditQuantityDialog;
  },
  set: (value) => {
    emit("update:showEditQuantityDialog", value);
  },
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
    });
    return;
  }

  if (inventoryProductQuantity.value.newQuantity === inventoryProductQuantity.value.oldQuantity) {
    toast.add({
      severity: "warn",
      summary: t("toast.no-change.summary"),
      detail: t("toast.no-change.detail"),
    });
    return;
  }

  saving.value = true;

  try {
    await setNewInventoryLevel(inventoryProductQuantity.value);

    toast.add({
      severity: "success",
      summary: t("inventory.edit-inventory.add-success"),
      closable: true,
    });

    visible.value = false;
  } catch (error) {
    if (error instanceof BadRequestError) {
      toast.add({
        severity: "error",
        summary: t("inventory.edit-inventory.update-failed"),
        closable: true,
      });
    }
  } finally {
    saving.value = false;
  }
};

const fetchProductWithBatches = async () => {
  if (!props.warehouseId || !props.productId) return;

  loading.value = true;
  try {
    inventoriedProductInfo.value = await getProductWithBatches(props.warehouseId, props.productId);
  } catch {
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await fetchProductWithBatches();

  inventoryProductQuantity.value.productId = inventoriedProductInfo.value.productId;
  inventoryProductQuantity.value.warehouseId = props.warehouseId;
  inventoryProductQuantity.value.oldQuantity = inventoriedProductInfo.value.quantityInStock;
  inventoryProductQuantity.value.newQuantity = inventoriedProductInfo.value.quantityInStock;
  initialInventoryProductQuantity.value = cloneDeep(inventoryProductQuantity.value);
});

const hasUpdates = computed(() => {
  return !isEqual(inventoryProductQuantity.value, initialInventoryProductQuantity.value);
});
const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  visible.value = false;
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const saveButtonTooltip = computed(() => {
  return hasUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-change-inventory {
  padding: 0 1.5rem;
  border: none;
}

:deep(.p-checkbox .p-checkbox.p-highlight) {
  border-color: var(--success-btn-bg);
  background: var(--success-btn-bg);
}
:deep(.formgroup-inline .field-checkbox) {
  margin: 0;
}

.c-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 26, 26, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
