<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #content>
            <div class="mx-4 my-0">
              <OrderReadyForPickingList
                v-model:selected-orders-ready-for-picking="selectedOrdersReadyForPicking"
                v-model:filters="filtersComputed"
                :orders-ready-for-picking="ordersReadyForPicking"
                :loading="isLoadingOrdersReadyForPicking"
                :page-size="pageSize"
                :page="page"
                :sort-order="sortOrder"
                :sort-field="sortField"
                :total-hits="totalHits"
                :show-sidebar="showSidebar"
                :customer-groups="customerGroups"
                :freight-methods="freightMethods"
                @update:page-size="onUpdatePageSize"
                @create-picking-lists="onCreatePickingLists"
                @update:sort-order="onUpdateSortOrdersReadyForPicking"
                @update:page="onUpdatePage"
                @update:sort-field="onUpdateSortField"
                @select-all="selectAll"
                @on-toggle-filter-sidebar="onToggleFilterSidebar"
                @order-ready-for-picking-refresh="onRefreshList"
              />
            </div>
          </template>
        </Card>

        <ConfirmDialog>
          <template #message>
            <div class="flex flex-col">
              <div class="flex items-center">
                <i class="p-confirm-dialog-icon pi pi-exclamation-triangle" />
                <span class="ml-2">{{ t("order-ready-for-picking.confirm-create-message") }}</span>
              </div>
              <div class="flex my-6 items-center">
                <label class="mr-2" for="print-picking-list">Print picking list</label>
                <Checkbox v-model="printImmediately" :binary="true" />
              </div>
              <div>
                <label for="printers">{{ t("common.print") }}:</label>
                <Select
                  id="printers"
                  v-model="selectedPrinter"
                  :options="printers"
                  option-label="name"
                  :placeholder="t('common.select-printer')"
                  pt:list:data-testid="printers-list"
                />
              </div>
            </div>
          </template>
        </ConfirmDialog>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" position="right">
          <OrderReadyForPickingFilterSidebar
            v-model:filters="filtersComputed"
            :customer-groups="customerGroups"
            :freight-methods="freightMethods"
            :loading="loading"
            :loading-customer-groups="isLoadingCustomerGroups"
            :loading-freight-methods="isLoadingFreightMethods"
          />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("common.filters.filter") }}</span>
            </div>

            <OrderReadyForPickingFilterSidebar
              v-model:filters="filtersComputed"
              :customer-groups="customerGroups"
              :freight-methods="freightMethods"
              :loading="loading"
              :loading-customer-groups="isLoadingCustomerGroups"
              :loading-freight-methods="isLoadingFreightMethods"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useConfirm } from "primevue/useconfirm";
import { useDocumentService } from "../document/DocumentService";

import OrderReadyForPickingFilterSidebar from "./OrderReadyForPickingFilterSidebar.vue";
import { OrderReadyForPicking } from "@/repositories/search/model/order/OrderReadyForPicking";
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { OrderReadyForPickingSearchRequest } from "@/repositories/search/model/order/OrderReadyForPickingSearchRequest";
import { useSearch } from "@/repositories/search/SearchService";
import OrderReadyForPickingList from "./OrderReadyForPickingList.vue";

import { useFreightMethods } from "@/repositories/freight-method/FreightMethodService";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { useCustomerGroups } from "@/repositories/customer-group/CustomerGroupService";
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { OrderWebPubSubMessage } from "./model/OrderWebPubSubMessage";
import { OrderStatus } from "@/repositories/search/model/order/OrderStatus";
import { Printer } from "../document/Printer";
import { useMobile } from "@/utils/MobileService";

const { getAllPrinters } = useDocumentService();
const { t } = useI18n();
const { getCustomerGroups } = useCustomerGroups();
const { getFreightMethods } = useFreightMethods();
const { orderReadyForPickingSearch } = useSearch();
const { createPickingLists } = usePickingListStore();
const { getWebPubSubUrl } = useSearch();

const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const isLoadingOrdersReadyForPicking = ref<boolean>(false);
const isLoadingCustomerGroups = ref<boolean>(false);
const isLoadingFreightMethods = ref<boolean>(false);
const loading = ref(false);
const selectedOrdersReadyForPicking = ref<OrderReadyForPicking[]>([]);
const query = ref<string>("*");
const showSidebar = ref(false);
const page = ref(1);
const pageSize = ref<number>(50);
const totalHits = ref(0);
const ordersReadyForPicking = ref<OrderReadyForPicking[]>([]);
const filters = ref<SearchFilters>(new SearchFilters());
const customerGroups = ref<CustomerGroup[]>([]);
const freightMethods = ref<FreightMethod[]>([]);
const webSocket = ref<WebSocket | null>();
const selectedPrinter = ref<Printer>();
const printers = ref<Printer[]>([]);
const printImmediately = ref<boolean>(true);
const sortField = ref("");
const sortOrder = ref(-1);
const { isMobile } = useMobile();

watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const filtersComputed = computed<SearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: SearchFilters) => {
  filters.value = value;
  page.value = 1;
  await searchForOrdersReadyForPicking();
};

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  await searchForOrdersReadyForPicking();
};

const onCreatePickingLists = async () => {
  let orderIds: string[] = [];

  const array = selectedOrdersReadyForPicking.value as OrderReadyForPicking[];
  if (array.length !== undefined) {
    if (array.length > 0) {
      orderIds = array.map((o) => o.orderId);
    }
  }

  if (orderIds.length > 0) {
    confirm.require({
      header: t("order-ready-for-picking.confirm-create-header"),
      message: t("order-ready-for-picking.confirm-create-message"),
      acceptLabel: t("order-ready-for-picking.confirm-accept"),
      rejectLabel: t("order-ready-for-picking.confirm-reject"),
      acceptClass: "c-confirm-create-picking",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        await onAcceptCreatePickingLists(orderIds);
      },
    });
  }
};

const onAcceptCreatePickingLists = async (orderIds: string[]) => {
  const createPickingListRequest = {
    orderIds: orderIds,
    printerName: selectedPrinter.value?.name ?? "",
    printImmediately: printImmediately.value,
  };
  await createPickingLists(createPickingListRequest);
  selectedOrdersReadyForPicking.value = [];
  toast.add({
    severity: "success",
    summary: t("order-ready-for-picking.created-picking-list.add-success"),
  });
};

const selectAll = () => {
  selectedOrdersReadyForPicking.value = [...ordersReadyForPicking.value];
  return selectedOrdersReadyForPicking.value;
};

const searchForOrdersReadyForPicking = async () => {
  isLoadingOrdersReadyForPicking.value = true;
  try {
    const request = new OrderReadyForPickingSearchRequest(query.value, page.value, pageSize.value, filters.value);
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    const response = await orderReadyForPickingSearch(request);
    ordersReadyForPicking.value = response.ordersReadyForPicking;
    totalHits.value = response.totalHits;
  } finally {
    isLoadingOrdersReadyForPicking.value = false;
  }
};

const onUpdatePage = (value: number) => {
  page.value = value;
  isLoadingCustomerGroups.value = true;
  searchForOrdersReadyForPicking();
};

const onUpdateSortOrdersReadyForPicking = (value: number) => {
  sortOrder.value = value;
  isLoadingCustomerGroups.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  isLoadingCustomerGroups.value = true;
};

const getPubSubUrl = async () => {
  const url: string = await getWebPubSubUrl("order");

  if (!url) {
    handleWebSocketError();
    return;
  }
  initializeWebSocket(url);
};

const initializeWebSocket = (url: string) => {
  webSocket.value = new WebSocket(url);
  webSocket.value.onmessage = handleWebSocketMessage;
  webSocket.value.onerror = handleWebSocketError;
};

const handleWebSocketError = () => {
  toast.add({
    severity: "warn",
    summary: t("order-ready-for-picking.websocket-error"),
    detail: t("order-ready-for-picking.websocket-error-details"),
    life: 5000,
  });
};

const handleWebSocketMessage = async (event: MessageEvent) => {
  const pubSubMessage = JSON.parse(event.data) as OrderWebPubSubMessage;

  const order = ordersReadyForPicking.value.find((o) => o.orderId === pubSubMessage.id);
  const isReadyForPicking = pubSubMessage.orderStatus === OrderStatus.Open && pubSubMessage.isReadyForPicking;

  if (order || isReadyForPicking) {
    await searchForOrdersReadyForPicking();
  }
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const fetchCustomerGroups = async () => {
  isLoadingCustomerGroups.value = true;
  try {
    customerGroups.value = await getCustomerGroups();
  } finally {
    isLoadingCustomerGroups.value = false;
  }
};

const fetchFreightMethods = async () => {
  isLoadingFreightMethods.value = true;
  try {
    freightMethods.value = await getFreightMethods();
  } finally {
    isLoadingFreightMethods.value = false;
  }
};

const getPrinters = async () => {
  printers.value = await getAllPrinters();
  selectedPrinter.value = printers.value[0];
};

const onRefreshList = async () => {
  selectedOrdersReadyForPicking.value = [];
  await searchForOrdersReadyForPicking();
};

onMounted(() => {
  searchForOrdersReadyForPicking();
  fetchCustomerGroups();
  fetchFreightMethods();
  getPubSubUrl();
  getPrinters();
});
</script>
