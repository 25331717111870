<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #content>
            <div class="mx-4 my-0">
              <InventoryProductList
                ref="inventoryProductList"
                v-model:filters="filtersComputed"
                v-model:selected-inventory-product="selectedInventoryProduct"
                :products="products"
                :selected-warehouse-id="selectedWarehouseIdComputed"
                :total-hits="totalHits"
                :page-size="pageSize"
                :page="page"
                :sort-order="sortOrder"
                :sort-field="sortField"
                :loading="loading"
                :show-sidebar="showSidebar"
                :warehouses="warehouses"
                :manufacturers="manufacturers"
                @update:sort-order="onUpdateSortInventoryOrder"
                @row-selected="onRowSelected"
                @update:sort-field="onUpdateSortField"
                @row-double-clicked="onRowDblClick"
                @open-inventory-product-overview="openInventoryProductOverview"
                @open-edit-inventory-quantity="openEditInventoryQuantity"
                @open-edit-inventory-cost="openEditInventoryCost"
                @open-transfer-inventory="openTransferInventory"
                @update:page="onUpdatePage"
                @update:page-size="onUpdatePageSize"
                @inventory-refresh="onRefreshList"
                @on-toggle-filter-sidebar="onToggleFilterSidebar"
                @inventory-changes-log-btn-clicked="openInventoryChangesLog"
              />
            </div>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" position="right">
          <InventoryFilterSidebar
            v-model:filters="filtersComputed"
            :warehouses="warehouses"
            :manufacturers="manufacturers"
            :loading="loading"
          />
        </Drawer>
      </div>
      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("common.filters.filter") }}</span>
            </div>
            <InventoryFilterSidebar
              v-model:filters="filtersComputed"
              :warehouses="warehouses"
              :manufacturers="manufacturers"
              :loading="loading"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <InventoryProductOverview
    v-if="showOverviewDialog && selectedInventoryProductId"
    v-model:show-overview-dialog="showOverviewDialog"
    :product-id="selectedInventoryProductId"
    :warehouse-id="selectedWarehouseIdComputed"
    :warehouses="warehouses"
    @update:show-overview-dialog="clearSelectedRow"
    @edit-inventory-product-quantity="openEditInventoryQuantity"
    @edit-inventory-product-cost="openEditInventoryCost"
    @edit-transfer-inventory="openTransferInventory"
  />

  <InventoryTransfer
    v-if="showTransferInventoryDialog && selectedInventoryProductId"
    v-model:show-transfer-inventory-dialog="showTransferInventoryDialog"
    :product-id="selectedInventoryProductId ?? ''"
    :warehouse-id="selectedWarehouseIdComputed"
    :warehouses="warehouses"
    @update:show-transfer-inventory-dialog="clearSelectedRow"
    @inventory-refresh="onRefreshList"
  ></InventoryTransfer>

  <InventoryProductQuantityEdit
    v-if="showEditQuantityDialog && selectedInventoryProductId"
    v-model:show-edit-quantity-dialog="showEditQuantityDialog"
    :product-id="selectedInventoryProductId ?? ''"
    :warehouse-id="selectedWarehouseIdComputed"
    @update:show-edit-quantity-dialog="clearSelectedRow"
  />

  <InventoryProductCostEdit
    v-if="showEditCostDialog && selectedInventoryProductId"
    v-model:show-edit-cost-dialog="showEditCostDialog"
    :product-id="selectedInventoryProductId"
    :warehouse-id="selectedWarehouseIdComputed"
    @update:show-edit-cost-dialog="clearSelectedRow"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import InventoryProductOverview from "../components/overview/InventoryProductOverview.vue";
import InventoryProductCostEdit from "../edit/InventoryProductCostEdit.vue";
import InventoryProductQuantityEdit from "../edit/InventoryProductQuantityEdit.vue";
import InventoryFilterSidebar from "../search/InventoryFilterSidebar.vue";
import { useRoute, useRouter } from "vue-router";

import { useSearch } from "@/repositories/search/SearchService";
import { ProductSearchRequest } from "@/repositories/search/model/product/ProductSearchRequest";
import { SearchProduct } from "@/repositories/search/model/product/SearchProduct";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturerService } from "@/repositories/manufacturer/ManufacturerService";
import { inventoryRoute } from "@/router/routes";
import { useMobile } from "@/utils/MobileService";
import InventoryProductList from "../search/InventoryProductList.vue";
import { type DataTableRowDoubleClickEvent, type DataTableRowSelectEvent } from "primevue/datatable";
import InventoryTransfer from "../components/overview/InventoryTransfer.vue";

const { getAllWarehouses } = useWarehouse();
const { t } = useI18n();
const { productSearch } = useSearch();
const { getManufacturers } = useManufacturerService();
const { isMobile } = useMobile();
const router = useRouter();
const inventoryProductList = ref();
const route = useRoute();
const products = ref<SearchProduct[]>([]);

const query = ref("");
const page = ref(1);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);
const totalHits = ref(0);
const filters = ref<ProductSearchFilters>(new ProductSearchFilters());
const showSidebar = ref(false);
const loading = ref(false);
const warehouses = ref<Warehouse[]>([]);
const manufacturers = ref<Manufacturer[]>([]);

const setRouteQuery = (name: string, query?: string) => {
  router.push({ path: route.path, query: { ...route.query, [name]: query } });
};

watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

//Fix for the issue where the selected row is not cleared when the user navigates back to the inventory list
let fixSelectedInventoryProductId = "";

const selectedInventoryProductId = computed<SearchProduct["id"] | undefined>({
  get: () => {
    if (route.params?.id === undefined) {
      return fixSelectedInventoryProductId;
    }

    if (route.params?.id === "") {
      return fixSelectedInventoryProductId;
    }

    return route.params?.id as SearchProduct["id"];
  },
  set: (id) => {
    fixSelectedInventoryProductId = "";
    if (id === undefined || id === "") {
      router.push({ path: inventoryRoute(), query: { ...route.query, modal: undefined } });
    } else {
      router.push({ path: inventoryRoute(id), query: route.query });
      fixSelectedInventoryProductId = id;
    }
  },
});

const clearSelectedRow = () => {
  inventoryProductList.value.clearSelectedRow();
};

const selectWasTriggeredFromEnterKey = (event: DataTableRowSelectEvent) =>
  event.originalEvent instanceof KeyboardEvent && (event.originalEvent as KeyboardEvent).key === "Enter";

const onRowSelected = (event: DataTableRowSelectEvent) => {
  selectedInventoryProductId.value = event.data?.id;
  if (!selectWasTriggeredFromEnterKey(event)) {
    return;
  }

  openInventoryProductOverview();
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onUpdateSortInventoryOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const selectedWarehouseIdComputed = computed<string>(() => {
  if (filters.value.warehouses.length <= 1 || route.query.warehouse === undefined) {
    return filters.value.warehouses[0] ? filters.value.warehouses[0] : "";
  }
  return route.query.warehouse as string;
});

const showOverviewDialog = computed<boolean>({
  get: () => !loading.value && route.query.modal === "view",
  set: (value) => {
    setRouteQuery("modal", value ? "view" : undefined);
    return value;
  },
});

const showTransferInventoryDialog = computed<boolean>({
  get: () => {
    const value = route.query?.modal as string;
    if (!loading.value && value === "edit-transfer") {
      return true;
    }
    return false;
  },
  set: (value) => {
    setRouteQuery("modal", value ? "edit-transfer" : undefined);
    return value;
  },
});

const showEditQuantityDialog = computed<boolean>({
  get: () => {
    const value = route.query?.modal as string; //This line is required to fix setRouteQuery event
    if (!loading.value && value === "edit-quantity") {
      return true;
    }
    return false;
  },
  set: (value) => {
    setRouteQuery("modal", value ? "edit-quantity" : undefined);
    return value;
  },
});

const showEditCostDialog = computed<boolean>({
  get: () => {
    const value = route.query?.modal as string; //This line is required to fix setRouteQuery event
    if (!loading.value && value === "edit-cost") {
      return true;
    }
    return false;
  },
  set: (value) => {
    setRouteQuery("modal", value ? "edit-cost" : undefined);
    return value;
  },
});

const selectedInventoryProduct = computed<SearchProduct>({
  get: () => {
    return products.value.find((product) => product?.id === selectedInventoryProductId.value) ?? new SearchProduct();
  },
  set: (value) => {
    selectedInventoryProductId.value = value?.id;
  },
});

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  selectedInventoryProductId.value = event.data?.id;

  openInventoryProductOverview();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const filtersComputed = computed<ProductSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: ProductSearchFilters) => {
  filters.value = value;
  page.value = 1;
  await search();
};

const openInventoryProductOverview = () => {
  if (selectedInventoryProductId.value === undefined) return;

  showOverviewDialog.value = true;
};

const openEditInventoryQuantity = () => {
  if (selectedInventoryProductId.value === undefined) return;
  showOverviewDialog.value = false;
  showEditQuantityDialog.value = true;
};

const openTransferInventory = () => {
  if (selectedInventoryProductId.value === undefined) return;
  showOverviewDialog.value = false;
  showTransferInventoryDialog.value = true;
};

const openEditInventoryCost = () => {
  if (selectedInventoryProductId.value !== undefined) {
    showOverviewDialog.value = false;
    showEditCostDialog.value = true;
  }
};

const openInventoryChangesLog = () => {
  router.push({ name: "inventory-changes-log" });
};

const fetchInventoryData = async () => {
  loading.value = true;
  try {
    warehouses.value = await getAllWarehouses();

    // If no warehouse is selected, default to the first warehouse
    if (filters.value.warehouses.length < 1 || filters.value.warehouses[0] === "") {
      filters.value.warehouses = [warehouses.value[0]?.id];
    }

    await search();

    manufacturers.value = await getManufacturers();
  } finally {
    loading.value = false;
  }
};

const search = async () => {
  loading.value = true;

  try {
    const request = new ProductSearchRequest(query.value ? query.value : "*");
    request.includeAvailability = true;
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await productSearch(request);

    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  query.value = route.query.search as string;

  const warehouseId = route.query.warehouse !== undefined ? (route.query.warehouse as string) : "";
  filters.value.warehouses = [warehouseId];

  await fetchInventoryData();
});

watch(
  () => route.query.search,
  async (querySearch) => {
    if (querySearch !== undefined) {
      query.value = querySearch as string;
      page.value = 1;
      await search();
    }
  },
);
</script>

<style scoped lang="scss">
.c-inventory-list {
  margin: var(--default-content-margin);
}
</style>
