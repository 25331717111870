<template>
  <Dialog
    id="inventory-count-import-dialog"
    v-model:visible="visible"
    :header="t('inventory.change-log.label')"
    :modal="true"
    :style="{ width: '40vw' }"
    class="inventory-dialog"
  >
    <div v-if="loading" class="c-loader-container">
      <ProgressSpinner class="w-16 h-16" />
    </div>
    <div v-else class="c-inventory-change-log">
      <div class="c-info-card">
        <div class="c-info-header">
          <div class="c-header-content">
            <div class="c-change-number">
              <span class="c-label">{{ t("inventory.change-log.change-number") }}</span>
              <span class="c-value">#{{ inventoryChangeDetails?.changeNumber }}</span>
            </div>
            <div class="c-product-info">
              <span class="c-label">{{ t("inventory.productname") }}</span>
              <h2>{{ inventoryChangeDetails?.productName }}</h2>
              <span class="c-product-number"
                >{{ t("inventory.change-log.product-number") }}: {{ inventoryChangeDetails?.productNumber }}</span
              >
            </div>
          </div>
        </div>
        <div class="c-info-grid">
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.warehouse") }}</span>
            <span class="c-info-value">{{ inventoryChangeDetails?.warehouseName }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.change-log.change-date") }}</span>
            <span class="c-info-value">{{
              inventoryChangeDetails?.changeDate ? d(inventoryChangeDetails.changeDate) : ""
            }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.change-log.quantity") }}</span>
            <span class="c-info-value">{{
              inventoryChangeDetails?.quantityChange ? n(inventoryChangeDetails.quantityChange, "decimal") : ""
            }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.reason") }}</span>
            <span class="c-info-value">{{ inventoryChangeDetails?.reason }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.change-log.comment") }}</span>
            <span class="c-info-value">{{ inventoryChangeDetails?.comment }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.change-log.total-change-value") }}</span>
            <span class="c-info-value">{{
              inventoryChangeDetails?.totalChangeValue ? n(inventoryChangeDetails.totalChangeValue, "decimal") : ""
            }}</span>
          </div>
          <div class="c-info-item">
            <span class="c-info-label">{{ t("inventory.change-log.batch-number") }}</span>
            <span class="c-info-value">{{ inventoryChangeDetails?.batchNumber }}</span>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { useInventory } from "@/api/inventory/InventoryService";
import { useProductService } from "@/api/product/ProductService";
import { InventoryChangeDetails } from "@/models/inventory/InventoryChangeDetails";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t, d, n } = useI18n();

const props = defineProps<{
  inventoryChangeLogId: string;
}>();

const visible = defineModel<boolean>("visible", { required: true, default: false });

const inventoryChangeDetails = ref<InventoryChangeDetails | null>(null);
const loading = ref<boolean>(false);
const { getInventoryChangeLogById } = useInventory();
const { getWarehouse } = useWarehouse();
const { getProductById } = useProductService();

const getInventoryChangeLog = async (id: string) => {
  try {
    loading.value = true;
    const inventoryChangeLog = await getInventoryChangeLogById(id);
    const warehouse = await getWarehouse(inventoryChangeLog.warehouseId);
    const product = await getProductById(inventoryChangeLog.productId);

    inventoryChangeDetails.value = InventoryChangeDetails.create(product, warehouse, inventoryChangeLog);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (props.inventoryChangeLogId) {
    getInventoryChangeLog(props.inventoryChangeLogId);
  }
});
</script>

<style scoped>
.inventory-dialog :deep(.p-dialog-content) {
  padding: 0;
}

.c-inventory-change-log {
  font-family: "Arial", sans-serif;
  color: #333;
}

.c-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.c-info-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.c-info-header {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
}

.c-header-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.c-change-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.c-change-number .c-label {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.c-change-number .c-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.c-product-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-product-info .c-label {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.c-product-info h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.c-product-number {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.c-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.c-info-item {
  display: flex;
  flex-direction: column;
}

.c-info-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.c-info-value {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
</style>
