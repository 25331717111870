<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button class="c-circular-button mr-4" data-testid="btn-detailed-overview" @click="emit('openDetailedOverview')">
        <i class="pi pi-search-plus c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.detailed") }}</span>
      </Button>

      <Button
        class="c-circular-button mr-4"
        data-testid="btn-change-inventory"
        @click="emit('editInventoryProductQuantity')"
      >
        <i class="pi pi-sliders-v c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.inventory") }}</span>
      </Button>

      <Button class="c-circular-button mr-4" data-testid="btn-change-cost" @click="emit('editInventoryProductCost')">
        <i class="pi pi-dollar c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.cost") }}</span>
      </Button>

      <Button
        class="c-circular-button mr-4 c-split-button"
        data-testid="btn-inventory-export"
        @click="visibleExportDialog = true"
      >
        <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
        <span class="px-4 m-auto">{{ t("inventory.export") }}</span>
      </Button>
      <Button
        class="c-circular-button mr-4"
        data-testid="btn-inventory-changes-log"
        @click="emit('inventoryChangesLogBtnClicked')"
      >
        <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
        <span class="px-4 m-auto">{{ t("inventory.change-log.inventory-changes-log") }}</span>
      </Button>
    </div>
    <div class="inline-flex">
      <div>
        <Button
          v-tooltip.bottom="{
            value: t('inventory.toolbar.sorting'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="btn-selected-icon"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isSortingVisible }"
          @click="(event: Event) => emit('openSortList', event)"
        >
          <i :class="selectedIcon"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('inventory.toolbar.refresh'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="c-refresh-button"
          severity="secondary"
          variant="text"
          size="small"
          @click="emit('refreshList')"
        >
          <i class="pi pi-refresh"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('inventory.toolbar.column-chooser'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="btn-column-chooser"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isChooserVisible }"
          @click="emit('openColumnChooser', $event)"
        >
          <i class="pi pi-list"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('inventory.toolbar.filter'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isFilterVisible }"
          @click="emit('onToggleFilterSidebar')"
        >
          <i class="pi pi-filter"></i>
        </Button>
      </div>
    </div>
  </div>

  <ExportInventoryProductsDialog v-model:visible-dialog="visibleExportDialog" :warehouses="warehouses" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import ExportInventoryProductsDialog from "../components/ExportInventoryProductsDialog.vue";

defineProps<{
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "openDetailedOverview"): void;
  (e: "editInventoryProductQuantity"): void;
  (e: "editInventoryProductCost"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "inventoryChangesLogBtnClicked"): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "openSortList", value: Event): void;
}>();

const { t } = useI18n();

const isSortingVisible = defineModel<boolean>("isSortingVisible");
const isChooserVisible = defineModel<boolean>("isChooserVisible");
const selectedIcon = defineModel<string>("selectedIcon");
const isFilterVisible = defineModel<boolean>("isFilterVisible");

const visibleExportDialog = ref<boolean>(false);
</script>
