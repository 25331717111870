<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        v-tooltip.bottom="
          selectedOrdersReadyForPicking.length === 0
            ? {
                value: t('order-ready-for-picking.context-menu.create-picking-list-disabled'),
                showDelay: 1000,
                hideDelay: 300,
              }
            : ''
        "
        data-testid="btn-create-picking-lists"
        class="c-circular-button mr-4"
        :disabled="selectedOrdersReadyForPicking.length === 0"
        @click="emit('createPickingLists')"
      >
        <i class="pi pi-list c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("order-ready-for-picking.context-menu.create-picking-lists") }}</span>
      </Button>
    </div>
    <div class="inline-flex">
      <div>
        <Button
          v-tooltip.bottom="{
            value: t('order-ready-for-picking.toolbar.sorting'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="btn-selected-icon"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isSortingVisible }"
          @click="(event: Event) => emit('openSortList', event)"
        >
          <i :class="selectedIcon"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('order-ready-for-picking.toolbar.refresh'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="c-refresh-button"
          severity="secondary"
          variant="text"
          size="small"
          @click="emit('refreshList')"
        >
          <i class="pi pi-refresh"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('order-ready-for-picking.toolbar.column-chooser'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="btn-column-chooser"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isChooserVisible }"
          @click="emit('openColumnChooser', $event)"
        >
          <i class="pi pi-list"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('order-ready-for-picking.toolbar.filter'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="filter-button"
          severity="secondary"
          variant="text"
          size="small"
          :class="{ 'c-toolbar-button-active': isFilterVisible }"
          @click="emit('onToggleFilterSidebar')"
        >
          <i class="pi pi-filter"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { OrderReadyForPicking } from "@/repositories/search/model/order/OrderReadyForPicking";

const { t } = useI18n();

defineProps<{
  selectedOrdersReadyForPicking: OrderReadyForPicking[];
}>();

const emit = defineEmits<{
  (e: "createPickingLists"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "openSortList", value: Event): void;
}>();

const isSortingVisible = defineModel<boolean>("isSortingVisible");
const isChooserVisible = defineModel<boolean>("isChooserVisible");
const selectedIcon = defineModel<string>("selectedIcon");
const isFilterVisible = defineModel<boolean>("isFilterVisible");
</script>
