<template>
  <div class="card">
    <InventoryCountLinesListToolbar
      :is-chooser-visible="isChooserVisible"
      @on-click-inventory-count-refresh="onClickInventoryRefresh"
      @open-column-chooser="openColumnChooser"
    />
    <DataTable
      ref="searchInventoryCountList"
      :key="renderKey as unknown as number"
      :value="lines"
      data-key="id"
      class="c-datatable"
      :paginator="true"
      :loading="loading"
      :rows="pageSize"
      :total-records="totalHits"
      sort-field="status"
      :striped-rows="true"
      selection-mode="single"
      data-testid="inventory-count-job-search-result"
      :rows-per-page-options="[50, 100]"
      scrollable
      scroll-height="75vh"
      :row-class="rowClass"
      :removable-sort="true"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :current-page-report-template="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      :sort-order="sortOrder"
      :reorderable-columns="true"
      @row-click="onRowDblClick"
      @row-select="onRowSelected"
      @page="onPageChange"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
    >
      <Column
        v-for="(col, subIndex) in selectedColumnsComputed"
        :key="col.field + '_' + subIndex"
        :field="col.field"
        :header="t(col.header)"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index: rowIndex }">
          <template v-if="col.field === InventoryProductListCountColumn.ProductNumber">
            <span :data-testid="`c-count-line-product-number-${rowIndex}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.Name">
            <span :data-testid="`c-count-line-product-name-${rowIndex}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.QuantityInStock">
            <span :data-testid="`c-count-line-inventory-${rowIndex}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.CostPrice">
            <span :data-testid="`c-count-line-cost-price-${rowIndex}`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.NewQuantity">
            <InputNumber
              v-if="rowIndex == editingRowIndex"
              ref="newQuantityInputRef"
              v-model="data[field as keyof typeof data]"
              mode="decimal"
              :min="0"
              input-class="c-row-input w-20"
              class="w-full"
              :data-testid="`c-count-line-new-quantity-${rowIndex}`"
              @update:model-value="onNewQuantityInput(data, rowIndex)"
              @keydown.tab.prevent="tabToNewCostPrice($event)"
            />
            <span v-if="rowIndex !== editingRowIndex" :data-testid="`c-count-line-new-quantity-${rowIndex}`">{{
              data[field as keyof typeof data]
            }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.NewCostPrice">
            <InputNumber
              v-if="rowIndex == editingRowIndex"
              ref="newCostPriceInputRef"
              v-model="data[field as keyof typeof data]"
              mode="decimal"
              :min="0"
              input-class="c-row-input w-24"
              class="w-full"
              :min-fraction-digits="2"
              :max-fraction-digits="2"
              :data-testid="`c-count-line-new-cost-price-${rowIndex}`"
              @update:model-value="onNewCostPriceInput(data, rowIndex)"
              @keydown.tab.prevent="tabToNextRow($event)"
            />
            <span v-if="rowIndex !== editingRowIndex" :data-testid="`c-count-line-new-cost-price-${rowIndex}`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.DiffInventory">
            <span :data-testid="`c-count-line-diff-inventory-${rowIndex}`">{{ data.diffInventory }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListCountColumn.DiffCostPrice">
            <span :data-testid="`c-count-line-diff-cost-${rowIndex}`">{{ n(data.diffCostPrice, "decimal") }}</span>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>

      <template #empty
        ><span data-testid="c-inventory-count-job-not-found">{{ t("inventory-count.not-found") }}</span></template
      >
    </DataTable>
    <PopOverColumnChooser
      ref="chooserRef"
      v-model:selected-columns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      @reset-columns="resetColumns"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { InventoryCountLine } from "../model/InventoryCountLine";
import { ref, computed } from "vue";
import { type DataTableRowDoubleClickEvent, type DataTableRowSelectEvent } from "primevue/datatable";
import { InventoryCountLineStatus } from "../model/InventoryCountLineStatus";
import { useTablePreferences } from "@cumulus/components";

import { InventoryProductListCountColumn } from "@/models/inventory/InventoryProductListCountColumn";
import PopOverColumnChooser from "../../warehouse/PopOverColumnChooser.vue";
import InventoryCountLinesListToolbar from "./InventoryCountLinesListToolbar.vue";

const { t, n } = useI18n();

const props = defineProps<{
  lines: InventoryCountLine[];
  loading: boolean;
  pageSize: number;
  totalHits: number;
  sortOrder: number;
}>();

const editingRowIndex = ref<number | null>(null);
const newQuantityInputRef = ref();
const newCostPriceInputRef = ref();
const selectedLine = ref<InventoryCountLine | null>(null);

const emit = defineEmits<{
  (e: "update:lines", lines: InventoryCountLine[]): void;
  (e: "failedLineClicked"): void;
  (e: "update:sortOrder", value: number): void;
  (e: "inventoryListRefresh"): void;
}>();

const inventoryProductListCountColumns = [
  { field: "productNumber", header: "inventory-count.product-number", sortable: true, class: "text-left" },
  { field: "productName", header: "inventory-count.product-name", sortable: true },
  {
    field: "quantityInStock",
    header: "inventory-count.inventory",
    sortable: true,
    class: "text-right",
  },
  { field: "costPrice", header: "inventory-count.cost-price", sortable: true, class: "text-right" },
  { field: "newQuantity", header: "inventory-count.new-quantity", sortable: true, class: "text-right" },
  {
    field: "newCostPrice",
    header: "inventory-count.new-cost-price",
    sortable: true,
    class: "text-right",
  },
  { field: "diffInventory", header: "inventory-count.diff-inventory", sortable: true, class: "text-right" },
  { field: "diffCostPrice", header: "inventory-count.diff-cost", sortable: true, class: "text-right" },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("inventoryProductListCount", inventoryProductListCountColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const onClickInventoryRefresh = () => {
  emit("inventoryListRefresh");
};

const onNewQuantityInput = (line: InventoryCountLine, index: number) => {
  const updateLines = props.lines.map((l, i) => {
    if (i === index) {
      return { ...l, newQuantity: line.newQuantity };
    }
    return l;
  });

  emit("update:lines", updateLines);
};

const onPageChange = () => {
  editingRowIndex.value = null;
};

const onNewCostPriceInput = (line: InventoryCountLine, index: number) => {
  const updateLines = props.lines.map((l, i) => {
    if (i === index) {
      return { ...l, newCostPrice: line.newCostPrice };
    }
    return l;
  });

  emit("update:lines", updateLines);
};

const lines = computed(() => {
  return props.lines.map((line) => ({
    ...line,
    diffCostPrice: line.newCostPrice - line.costPrice,
    diffInventory: line.newQuantity - line.quantityInStock,
  }));
});

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};
const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  const line = event.data as InventoryCountLine;

  if (line.status === InventoryCountLineStatus.Failed) {
    emit("failedLineClicked");
    return;
  }

  editingRowIndex.value = event.index;
};

const rowClass = (line: InventoryCountLine) => {
  return {
    "c-failed-row": line.status === "Failed",
  };
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }

  selectedLine.value = event.data;
  editingRowIndex.value = event.index;
};

const tabToNewCostPrice = (event: KeyboardEvent) => {
  event.stopPropagation();

  const element = newCostPriceInputRef.value.$el as HTMLElement;
  if (element != null) {
    element.querySelector("input")?.select();
  }
};

const tabToNextRow = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (editingRowIndex.value !== null) {
    editingRowIndex.value++;
  }
  const element = newQuantityInputRef.value.$el as HTMLElement;

  if (element != null) {
    element.querySelector("input")?.select();
  }
};
</script>

<style lang="scss" scoped>
:deep(.c-row-input.p-inputnumber-input) {
  --border-color: var(--select-border);
  font-size: 0.9rem;
  padding: 0.5rem;
  height: 1.8rem;
}

.c-datatable :deep(.p-datatable-thead > tr > th > div) {
  display: inline-flex;
}

.c-datatable :deep(.p-datatable-selectable-row.c-failed-row) {
  background-color: var(--inventory-count-job-line-failed) !important;
}
</style>
