<template>
  <label for="inventory-new-inventory-value" class="c-text-ellipsis mb-2">{{
    t(`inventory.edit-inventory.new-inventory`)
  }}</label>

  <InputNumber
    id="inventory-new-inventory-value"
    v-model="newQuantity"
    :allow-empty="false"
    :min="0"
    :highlight-on-focus="true"
    class="w-full c-input-width"
    data-testid="inventory-new-inventory-value"
    @input="(event) => (newQuantity = event.value as number)"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  currentQuantity: number;
}>();

const newQuantity = defineModel("newQuantity", {
  type: Number,
});
</script>

<style scoped lang="scss">
.c-input-width {
  :deep(.p-inputnumber-input) {
    min-width: 0;
  }
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(.p-inputtext) {
  text-align: right;
}
</style>
