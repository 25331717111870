<template>
  <PackingNoteToolbar
    :is-chooser-visible="isChooserVisible"
    @packing-note-refresh="onClickPackingNoteRefresh"
    @open-column-chooser="openColumnChooser"
  />

  <ContextMenu ref="cm" :model="menuModel" data-testid="packing-note-context-menu" />
  <DataTable
    :key="renderKey as unknown as number"
    v-model:context-menu-selection="selectedPackingNote"
    :value="filteredPackingNotes"
    data-key="id"
    :auto-layout="true"
    responsive-layout="stack"
    breakpoint="999px"
    selection-mode="single"
    class="c-packing-note-table c-datatable"
    :loading="loading"
    :paginator="true"
    :rows="25"
    :current-page-report-template="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    striped-rows
    context-menu
    data-testid="packing-note-table"
    :sort-field="sortField"
    :sort-order="sortOrder"
    scrollable
    scroll-height="75vh"
    :resizable-columns="true"
    column-resize-mode="fit"
    removable-sort
    :reorderable-columns="true"
    @row-dblclick="onRowDblClick"
    @row-contextmenu="onRowContextMenu"
    @sort="onSort"
    @page="onPage"
    @column-resize-end="onColumnResizeEnd"
    @column-reorder="onColumnReorder"
  >
    <Column
      v-for="(col, subIndex) of selectedColumnsComputed as unknown as DataTableColumn[]"
      :key="col.field + '_' + subIndex"
      :field="col.field"
      :header="t(col.header)"
      :class="col.class"
      :sortable="col.sortable"
      :pt="{
        headerCell: {
          id: col.field,
        },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template #body="{ field, data, index: rowIndex }">
        <template v-if="col.field === PackingNoteListColumn.CreatedDateUtc">
          {{ d(data.createdDateUtc, "short") }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.PackingNoteNumber">
          {{ data.packingNoteNumber }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.OrderNumber">
          {{ data.orderNumber }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.FreightMethodName">
          {{ data.freightMethodName }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.CustomerNumber">
          {{ data.customerNumber }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.InvoiceName">
          {{ data.invoiceName }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.NumberOfLines">
          {{ data.numberOfLines }}
        </template>
        <template v-else-if="col.field === PackingNoteListColumn.Pdf">
          <img :data-testid="'btn-show-pdf-' + rowIndex" :src="pdfIcon" @click="downloadPdf(data.id)" />
        </template>

        <template v-else>
          {{ data[field as keyof typeof data] }}
        </template>
      </template>
    </Column>

    <template #loading>
      <span v-if="loading">{{ t("common.loading") }}</span>
    </template>
    <template #empty>
      <span>{{ t("packing-note.no-result") }}</span>
    </template>
  </DataTable>
  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="resetColumns"
  />

  <PrintDialog v-if="showPrintModal" v-model:visible-dialog="showPrintModal" :packing-note="selectedPackingNote" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, nextTick, onMounted, ref } from "vue";
import {
  type DataTablePageEvent,
  type DataTableRowClickEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import PrintDialog from "../components/PackingPrintDialog.vue";
import { useDocumentService } from "../../document/DocumentService";
import { usePackingNoteStore } from "../api/PackingNoteStore";
import { PackingNote } from "../models/PackingNote";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { PackingNoteListColumn } from "../models/PackingNoteListColumn";
import PackingNoteToolbar from "./PackingNoteToolbar.vue";
import { PopOverColumnChooser } from "@cumulus/components";

const { t, d } = useI18n();
const toast = useCumulusToast(useToast());
const packingNoteStore = usePackingNoteStore();
const { getPackingNotes } = packingNoteStore;
const { packingNotes, loading } = storeToRefs(packingNoteStore);
const { getPackingNoteUrl } = useDocumentService();
const query = ref("");
const showPrintModal = ref(false);
const selectedPackingNote = ref<PackingNote>();
const cm = ref();
const sortField = ref("");
const sortOrder = ref(-1);

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
}>();

const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const packingNoteSearchColumns: DataTableColumn[] = [
  { field: "createdDateUtc", header: "packing-note.created-date", sortable: true },
  {
    field: "packingNoteNumber",
    header: "packing-note.packing-note-number",
    class: "text-right",
    sortable: true,
  },
  { field: "orderNumber", header: "packing-note.order-number", class: "text-right", sortable: true },
  { field: "freightMethodName", header: "packing-note.freight-method-name", class: "text-right", sortable: true },
  { field: "customerNumber", header: "packing-note.customer-number", class: "text-right", sortable: true },
  { field: "invoiceName", header: "packing-note.customer-name", class: "text-right", sortable: true },
  {
    field: "numberOfLines",
    header: "packing-note.quantity-of-order-items",
    class: "text-right",
    sortable: true,
  },
  {
    field: "pdf",
    header: "packing-note.pdf",
    class: "c-pdf-icon",
    sortable: false,
  },
];

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("packingNoteSearch", packingNoteSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const filteredPackingNotes = computed<PackingNote[]>(() => {
  if (packingNotes.value.length === 0) {
    return [];
  }
  return packingNotes.value.filter((packing: PackingNote) => {
    return (
      packing.orderNumber.toString().startsWith(query.value.trim()) ||
      packing.packingNoteNumber.toString().startsWith(query.value.trim())
    );
  });
});

const onClickPackingNoteRefresh = async () => {
  loading.value = true;
  await getPackingNotes();
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  downloadPdf((event.data as PackingNote).id);
};

const downloadPdf = async (id: string) => {
  try {
    loading.value = true;
    const url = await getPackingNoteUrl(id);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("packing-note.download-link-missing"),
      });
    }
  } finally {
    loading.value = false;
  }
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }

    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

onMounted(async () => {
  await getPackingNotes();
});
</script>

<style scoped lang="scss">
.c-packing-note-table {
  :deep(.p-datatable-thead > tr > .text-right > .p-column-header-content > .p-column-title) {
    width: 100%;
    text-align: right;
  }
}
.c-packing-note {
  margin: var(--default-content-margin);
}
.c-packing-note .card {
  overflow: auto;
}

:deep(.c-datatable.p-datatable .p-datatable-tbody > tr > td.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

:deep(.c-datatable.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

.c-header-icon-container {
  background: transparent;
}
</style>
