<template>
  <FloatLabel variant="on">
    <InputText
      id="warehouse-name"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('warehouse.name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="warehouse-name"
      :model-value="warehouseName"
      class="inputfield w-full"
      :class="{ 'p-invalid': validate.warehouseName.$error }"
      @input="warehouseName = ($event.target as HTMLInputElement).value"
    />
    <label for="warehouse-name">{{ t("warehouse.name") }}</label>
  </FloatLabel>

  <small v-if="validate.warehouseName.$error" class="p-error" data-testid="warehouse-name-error">
    {{ validate.warehouseName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  existingWarehouseNames: Array<string>;
}>();

const warehouseName = defineModel<string>("warehouseName", { required: true, default: "" });

const warehouseNameAlreadyExists = helpers.withMessage(
  ({ $model }) => {
    return t("validations.exists", { property: $model });
  },
  (value: string) => {
    return !props.existingWarehouseNames.includes(value);
  },
);

const rules = {
  warehouseName: {
    required,
    warehouseNameAlreadyExists,
  },
};

const validate = useVuelidate(rules, { warehouseName });
</script>
