<template>
  <div class="c-chips">
    <ul v-if="hasActiveFilters" class="c-chips-multiple-container" data-testid="c-active-filters-container">
      <li>
        <Button
          type="button"
          :label="t('common.filters.clear-filter')"
          icon="pi pi-times-circle"
          class="text-sm mr-4 p-1.5"
          data-testid="clear-filter-btn"
          rounded
          @click="clearFilters"
        />
      </li>
      <FilterItem
        v-if="filters.manufacturers.length !== null"
        :values="manufacturersComputed"
        filter-type="manufacturers"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.productStatuses.length !== null"
        :values="filters.productStatuses.map((status) => t(`inventory.product-status.${status.toLowerCase()}`))"
        filter-type="productStatuses"
        @remove-filter="removeFilter"
      />
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import FilterItem from "./FilterItem.vue";
import type { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";

const { t } = useI18n();

const props = defineProps<{
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: ProductSearchFilters): void;
}>();

const clearFilters = () => {
  const filters = new ProductSearchFilters();
  emit("update:filters", filters);
};

const removeFilter = (filterType: keyof ProductSearchFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof ProductSearchFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  }

  emit("update:filters", filters);
};

const manufacturersComputed = computed<string[]>(() => {
  return props.filters.manufacturers.map((id) => {
    const manufacturer = props.manufacturers.find((m) => m.id === id);
    return manufacturer?.name ?? "";
  });
});

const hasActiveFilters = computed<boolean>(() => {
  return props.filters.manufacturers.length > 0 || props.filters.productStatuses.length > 0;
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
