<template>
  <Dialog
    id="inventory-count-import-dialog"
    v-model:visible="visible"
    :header="t('common.print')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-3/12"
  >
    <div class="formgrid grid grid-cols-12 gap-4">
      <div v-if="printixNotFound" class="col-span-12 mt-2 ml-2" data-testid="printix-not-found-message">
        <i18n-t keypath="packing-note.setup-printix-message" tag="span" for="packing-note.printix">
          <a href="https://manuals.printix.net/administrator/1/en/topic/getting-started" target="_blank">{{
            t("packing-note.printix")
          }}</a>
        </i18n-t>
      </div>
      <div class="field col-span-12">
        <label for="dropdown-printer" class="label">{{ t("packing-note.printers") }}</label>
        <Select
          id="dropdown-printer"
          v-model="selectedPrinter"
          :options="printers"
          option-label="name"
          option-value="name"
          class="inputfield w-full"
          :loading="isLoading"
          pt:list:data-testid="dropdown-printer-list"
        />
      </div>
    </div>

    <div class="flex justify-end">
      <Button
        class="c-circular-button mr-4"
        data-testid="btn-correction-cancel"
        @click="emit('update:visibleDialog', false)"
      >
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.cancel") }}</span>
      </Button>
      <Button
        data-testid="btn-print"
        class="c-circular-button mr-4"
        :disabled="isSaving || printixNotFound || !selectedPrinter || !props.packingNote"
        @click="onPrint"
      >
        <ProgressSpinner v-if="isSaving" class="w-16 h-16" />
        <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.print") }}</span>
      </Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Printer } from "../../document/Printer";
import { useDocumentService } from "../../document/DocumentService";
import { DefaultPrinterInfo } from "../../document/DefaultPrinterInfo";
import { DocumentType } from "../../document/DocumentType";
import { PrintDocumentRequest } from "../../document/PrintDocumentRequest";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { NotFoundError } from "@cumulus/http";
import { PackingNote } from "../models/PackingNote";

const props = defineProps<{
  visibleDialog: boolean;
  packingNote?: PackingNote;
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
}>();

const { t } = useI18n();
const { getAllPrinters, getDefaultPrinterByDocumentType, printDocument, getPackingNoteUrl } = useDocumentService();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);
const isLoading = ref(false);
const printixNotFound = ref(false);
const printers = ref<Printer[]>([]);
const defaultPrinter = ref<DefaultPrinterInfo>();
const selectedPrinter = ref<string | null>(null);

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

const onPrint = async () => {
  try {
    isSaving.value = true;

    if (!selectedPrinter.value || !props.packingNote) {
      return;
    }

    const headerTitle = `${t("packing-note.header")} - ${props.packingNote.packingNoteNumber}`;
    const url = await getPackingNoteUrl(props.packingNote.id);

    const request = new PrintDocumentRequest(headerTitle, selectedPrinter.value, url);
    await printDocument(request);

    toast.add({
      severity: "success",
      summary: t("packing-note.print-success"),
      detail: t("packing-note.print-success-detail", { printerName: selectedPrinter.value }),
    });

    emit("update:visibleDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const fetchPrinters = async () => {
  try {
    isLoading.value = true;
    printers.value = await getAllPrinters();
    defaultPrinter.value = await getDefaultPrinterByDocumentType(DocumentType.PackingNote);
  } catch (error) {
    if (error instanceof NotFoundError) {
      printixNotFound.value = true;
    }
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchPrinters();

  if (defaultPrinter.value) {
    selectedPrinter.value = printers.value.find((p) => p.name === defaultPrinter.value?.printerName)?.name || null;
  }
});
</script>
