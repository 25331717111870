import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Routes",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../b2c/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../b2c/OAuth2Signout.vue"),
        },
        {
          name: "logout",
          path: "/logout",
          component: (): Component => import("../b2c/Logout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../b2c/Failed.vue"),
        },
      ]
    : [];

export const inventoryRoute = (id?: string) => "/warehouse/inventory/search" + (id ? `/${id}` : "");

export const inventoryRoutes: RouteRecordRaw[] = [
  {
    name: "warehouse/inventory/search",
    path: inventoryRoute(":id?"),
    component: (): Component => import("@/inventory/views/InventoryProductSearch.vue"),
    meta: {
      authRequired: true,
      title: "inventory.header.title",
    },
  },
  {
    name: "inventory-changes-log",
    path: "/warehouse/inventory/changes",
    component: (): Component => import("@/inventory/views/InventoryChangeLogsList.vue"),
    meta: {
      authRequired: true,
      title: "inventory.header.title",
    },
  },
];

export const inventoryCountRoutes: RouteRecordRaw[] = [
  {
    name: "inventory-count-search",
    path: "/warehouse/inventory-count/search",
    component: (): Component => import("@/inventory-count/views/InventoryCountJobSearch.vue"),
    meta: {
      authRequired: true,
      title: "inventory-count.header",
    },
  },
  {
    name: "inventory-count-job",
    path: "/warehouse/inventory-count/:id",
    component: (): Component => import("@/inventory-count/views/InventoryCountJob.vue"),
    meta: {
      authRequired: true,
      title: "inventory-count.header",
    },
  },
];

export const goodsOutRoutes: RouteRecordRaw[] = [
  {
    name: "order-ready-for-picking",
    path: "/warehouse/goods-out/order-ready-for-picking",
    component: (): Component => import("@/goods-out/ready-for-picking/OrderReadyForPickingSearch.vue"),
    meta: {
      authRequired: true,
      title: "order-ready-for-picking.header",
    },
  },
  {
    name: "open-picking-lists",
    path: "/warehouse/goods-out/open-picking-lists",
    component: (): Component => import("@/goods-out/picking-list/OpenPickingSearch.vue"),
    meta: {
      authRequired: true,
      title: "picking-list.header",
    },
  },
  {
    name: "picking-list",
    path: "/warehouse/goods-out/picking-list/:pickingListId",
    component: (): Component => import("@/goods-out/picking-list/PickingList.vue"),
    meta: {
      authRequired: true,
      title: "picking-list.header-view-picking-list",
    },
  },
  {
    name: "packing-notes",
    path: "/warehouse/goods-out/packing-notes",
    component: (): Component => import("@/goods-out/packing-note/views/PackingNoteSearch.vue"),
    meta: {
      authRequired: true,
      title: "packing-note.header",
    },
  },
];

export const warehouseRoutes: RouteRecordRaw[] = [
  {
    name: "warehouse-configurations",
    path: "/warehouse/configurations",
    component: (): Component => import("@/warehouse/WarehouseList.vue"),
    meta: {
      authRequired: true,
      title: "warehouse.header",
    },
  },
];
